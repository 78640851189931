@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";

.control-panel {
  @include flex($align-items: center, $justify-content: space-between);

  padding: 0 2.75rem;
  // ---> was the value 8rem
  height: 7.5rem;
  box-shadow: inset 0px -0.5px 0px #e0e4f8;
  overflow: visible;

  @include mobile {
    overflow: scroll;
  }

  @include tablet-max {
    height: 6rem;
    padding: 0 2rem;
    transition: height 0.3s, opacity 0.2s 0.1s;
  }

  &--header-hidden {
    @include tablet-max {
      height: 0;
      opacity: 0;
      transition: height 0.3s, opacity 0.2s;
    }
  }

  &--no-padding {
    padding: 0;
  }

  &--no-border {
    border: none;
  }

  &__children {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__title {
    margin-right: 2rem;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: $dark-gray;

    @include tablet-max {
      font-size: 1.4rem;
    }
  }
}
