@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/utils";

.text-with-icon {
  @include flex($align-items: center, $justify-content: center);

  line-height: 1.35;
  text-align: center;

  &__icon {
    @include backgroundImage($background-size: contain);

    margin-right: .8rem;

    @include tablet-max {
      margin-right: 0.4rem;
    }

    &--hide-label {
      margin-right: 0;
    }

    &--hide-label-on-mobile {
      @include tablet-max {
        margin-right: 0;
      }
    }

    &--margin-auto {
      margin: 0 auto;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &--blue {
    color: $dark-blue;
  }

  &--black {
    color: $black;
  }

  &--red {
    color: $red;
  }

  &--gray {
    color: $gray;
  }

  &--white {
    color: $white;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--text-nowrap {
    white-space: nowrap;
  }

  &--hide-label {
    font-size: 0 !important;
  }

  &--hide-label-on-mobile {
    @include tablet-max {
      font-size: 0 !important;
    }
  }

  &--break-word {
    word-break: break-word;
  }

  &--block {
    width: 100%;
  }
}
.label-text-rule {
  white-space: break-spaces;
}
