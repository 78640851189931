@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/media-queries";

.simulation-page {
  display: grid;
  grid-template-columns: 50% repeat(8, auto);
  grid-template-rows: repeat(20, auto);
  grid-gap: 1.2rem;

  //noinspection CssOverwrittenProperties
  @include tablet-max {
    padding-bottom: (5.6rem + 1rem + 0.5rem);
    padding-bottom: calc(
      env(safe-area-inset-bottom) + #{5.6rem + 1rem + 0.5rem}
    );
  }

  @include desktop-max {
    display: flex;
    flex-direction: column;
  }

  .data-grid__wrapper {
    flex-grow: initial;
  }

  &__rules {
    &-block {
      display: grid;
      grid-template-rows: repeat(11, 1fr);
      grid-template-columns: repeat(12, 1fr);
      grid-column: 2/9;
      grid-row: 1/7;

      @include desktop-max {
        order: 2;
      }

      &-title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #1e264d;

        grid-row: 1/2;
        grid-column: 1/4;
      }

      & .simulation-page__rules-text-input {
        grid-row: 2/-1;
        grid-column: 1/-1;

        display: flex;
        flex-direction: column;

        .ql-container {
          height: 330px;
        }
      }
    }
    &_language-btn {
      padding: 0.4rem;
      border-radius: 2px;
      font-weight: 700;
      font-size: 14px;
      background: $dark-blue;
      color: white;
      transition: opacity 0.2s ease;
      &:hover {
        opacity: 0.85;
      }
      &:active {
        opacity: 0.7;
      }
    }
  }

  &-general {
    &-wrapper {
      grid-column: 1/1;
      grid-row: 1/-1;
    }

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;

    &__info,
    &__links {
      width: 100%;

      @include tablet-max {
        max-width: 100%;
      }
    }
  }

  &-game-type {
    grid-column: 1/1;
    grid-row: 1/6;

    @include desktop-large-max {
      flex-wrap: wrap;
    }

    &__roles {
      grid-row: 1/6;
      grid-column: 1/2;

      @include laptop-max {
        grid-row: 5/6;
        grid-column: 1/2;
      }

      @include desktop-max {
        order: 3;
      }
    }
  }

  &__save-btn {
    &--desktop {
      @include tablet-max {
        display: none;
      }
    }

    &--mobile {
      display: none;
      z-index: 2;

      @include tablet-max {
        display: block;
        position: fixed;
        left: 1rem;
        right: 1rem;

        //noinspection CssOverwrittenProperties
        bottom: 1rem;
        //noinspection CssOverwrittenProperties
        bottom: calc(env(safe-area-inset-bottom) + 1rem);
      }
    }
  }
}
