@import "../../../app/styles/variables/variables.scss";
@import "../../../app/styles/mixins/grid.scss";

.block {
  background: $neutral-n-0;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0px 0px 20px 0px rgba(40, 58, 151, 0.2);
}

.switcher {
  width: 100%;
  max-width: 316px;

  &_wrapper {
    @include flex(center, flex-end);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}
