.desired-role-container {
  padding: 4rem;
  max-width: 580px;
  &_wrapper {
    border-radius: 4rem;
    background: white;
    box-shadow: 0 0 20px 0 rgba(40, 58, 151, 0.20);
  }
  &__title {
    display: flex;
    font-size: 20px;
    font-weight: 800;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    img {
      width: 80px;
      height: 80px;
      margin-right: 20px;
    }
  }
  &__hide {
    display: none;
  }
  &__content {
    display: grid;
    grid-row-gap: 20px;
    padding-bottom: 30px;
    align-items: center;
    width: 500px;
  }
  @media (max-width: 767px) {
    &__content {
      width: auto;
    }
  }
  &-waiting_status {
    display: flex;
    font-weight: 700;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;

    &__image {
      margin-left: 20px;

      img {
        animation: 1s linear 0s normal none infinite running rotate;
        transform-origin: 40% 60%;
        width: 40px;
        height: 40px;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;

    button {
      &:first-child {
        margin-right: 1rem;
      }
      font-size: 1.4rem;
    }

    .button--block {
      flex-grow: 0;
      width: calc((100% - 1rem) / 2);
    }
  }

  .base-select {
    &__menu {
      width: 500px;
    }
    @media (max-width: 767px) {
      &__menu {
        width: auto;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
