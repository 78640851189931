@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils";

.base-accordion {
  width: 56rem;

  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }

  @include tablet-max {
    width: 100%;
  }

  &--full-width {
    width: 100%;
  }

  &--with-shadow {
    box-shadow: 0 0 5rem 0 rgba(100, 163, 198, 0.1);
  }

  &--isOver {
    background-color: red;
  }

  &__title {
    @include flex($align-items: center, $justify-content: space-between);

    padding: 0.8rem 1.6rem;
    min-height: 4.4rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    color: $black;

    &:not(&--arrow-on-left)::after,
    &--arrow-on-left::before {
      @include backgroundImage($background-size: contain);

      content: "";
      display: block;
      width: 3.2rem;
      height: 3.2rem;
      background-image: url("../../images/icons/arrow-down-grey.svg");
    }

    &--active {
      &::after {
        transform: rotate(180deg);
      }
    }

    &--dark {
      background-color: $extra-light-purple;
    }

    &--is-over {
      background-color: $neutral-n-50;
    }

    &--with-border {
      border-radius: 1rem 1rem 0 0;
    }

    &--rounded {
      border-radius: $border-radius;
    }

    &--align-left {
      justify-content: flex-start;
    }

    &--light-green {
      background-color: $light-green;

      &--is-over {
        background-color: $green;
      }
    }

    &--light-red {
      background-color: $light-red;
      &--is-over {
        background-color: $red-r-40;
      }
    }
  }

  &__description.ql-editor {
    font-weight: normal;
    font-size: 1.4rem;

    img {
      width: 100%;
    }

    iframe {
      width: 100%;
      height: 30rem;
    }
  }
}
