@import "src/app/styles/mixins/media-queries";

.assessment-members-popup {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 70rem;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;

  &--solo {
    width: 70rem;

    @include mobile {
      width: 100%;
    }
  }

  &--team {
    width: 78rem;

    @include mobile {
      width: 74rem;
    }
  }

  &--corp {
    width: 82rem;

    @include mobile {
      width: 74rem;
    }
  }

  @include tablet-max {
    max-width: 100%;
  }
}
