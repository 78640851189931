@import "src/app/styles/mixins/media-queries";

.code-block {
  display: flex;

  margin-bottom: 2rem;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: var(--black);

  &__title {
    margin-top: 0.5rem;
    min-width: 13rem;
    white-space: pre-wrap;
  }

  &__description {
    @include mobile {
      overflow: hidden;
    }

    &-tooltip {
      position: relative;
      z-index: 2;

      @include tablet {
        display: none;
      }

      &-item {
        max-width: calc(100vw - 5rem);
      }
    }
  }
}
