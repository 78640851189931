@import "src/app/styles/mixins/media-queries";

.ratings-page-data-grid-wrapper {
  height: calc(100vh - 10rem);

  @include mobile-landscape {
    height: initial;
  }

  @include tablet-landscape {
    height: initial;
  }
}

.data-grid__dropdown {
  max-width: 26rem;
}