@import "src/app/styles/mixins/media-queries";
@import "../../../../../../../app/styles/mixins/grid";

.cell {
  @include flex(center);

  font-weight: 600;
  font-size: 1.6rem;
  padding: 0.8rem 2rem;
  color: $neutral-n-300;

  &.transparent {
    opacity: 0.5;
  }

  &.dragging {
    opacity: 40%;
  }

  &.ceoPlaceholder {
    background-color: transparent;
    border: 1px dashed $neutral-n-100;
    border-radius: $border-radius;
    color: $neutral-n-100;
    width: 100%;
    padding: 0.8rem 2rem;

    .name {
      text-decoration: none;
    }

    &__isOver {
      border-color: $dark-blue;
      color: $dark-blue;
    }
  }

  &:nth-child(odd) {
    background-color: $neutral-n-10;
  }

  &:last-child {
    border-radius: 0 0 2rem 2rem;
  }

  &--blue {
    color: $blue;
  }

  &.ceo {
    width: 100%;
    background: $neutral-n-0;
    border-radius: $border-radius;
    box-shadow: 0 0 20px 0 rgba(40, 58, 151, 0.2);
  }

  .name {
    margin: 0 1.2rem 0 1.6rem;
    text-decoration: underline;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .select {
    font-weight: 400;

    svg {
      margin-left: 0.4rem;
    }
  }

  .ceoLabel {
    color: $neutral-n-300;
    font-size: $font-size-3;
    font-weight: 400;
  }

  .buttonCross {
    @include flex(center, center);
    margin-left: auto;
  }
}

.base-avatar__admin {
  border: 2px solid #ffd602;
}

.base-avatar__hr {
  border: 2px solid #72fb02;
}
