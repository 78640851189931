@import "../../../app/styles/mixins/grid";
@import "../../../app/styles/mixins/utils";

.checkbox {
  cursor: pointer;
  position: relative;
  display: inline-block;

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  &__label {
    @include flex($inline: true);

    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
    transition: opacity 0.2s ease;

    &::before {
      @include backgroundImage();

      min-width: 2rem;
      width: 2rem;
      height: 2rem;
      content: "";
      display: block;
      border: 0.1rem solid $extra-light-gray;
      box-sizing: border-box;
      border-radius: 0.4rem;
    }

    &--bold {
      font-weight: 600;
    }

    &--for-dark-bg {
      &::before {
        border-color: $dark-gray;
      }
    }

    &--for-sort {
      align-items: center;
      font-weight: 600;

      @include mobile {
        font-weight: inherit;
      }

      &::before {
        border: none;
        min-width: 1.1rem;
        width: 1.1rem;
        height: 0.8rem;
        background-image: url("../../images/icons/checkbox-check.svg");
        opacity: 0;
        transition: 200ms ease-in-out;
        transform: scale(0);
      }
    }

    &--valign {
      &_top {
        align-items: flex-start;
      }

      &_middle {
        align-items: center;
      }

      &_bottom {
        align-items: flex-end;
      }
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__input:checked ~ &__label {
    &::before {
      background-color: $dark-blue;
      border-color: $dark-blue;
    }

    &--for-dark-bg {
      &::before {
        background-color: $light-blue;
        border-color: $light-blue;
      }
    }

    &--for-sort {
      color: $light-blue;

      &::before {
        background-color: transparent;
        border-color: transparent;
        transform: scale(1);
      }
    }
    &--disabled {
      opacity: 0.5;

      &::before {
        border-color: $extra-light-gray;
        cursor: not-allowed;
      }
    }
  }

  &--checked &__label {
    &::before {
      background-image: url("../../images/icons/checkbox.svg");
    }

    &--for-sort {
      &::before {
        background-image: url("../../images/icons/checkbox-check.svg");
        opacity: 1;
      }
    }
  }

  &--checked-plus &__label {
    &::before {
      background-image: url("../../images/icons/checkbox-reset.svg");
    }
  }

  &__input:focus &__label {
    &::before {
      border-color: $dark-blue;
    }

    &--for-dark-bg {
      &::before {
        border-color: $white;
      }
    }
  }

  &__label-text {
    margin-left: 1rem;
  }
}
