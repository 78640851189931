@import "src/app/styles/variables/variables";

.test-formulas-item {
  &__header {
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $black;
  }

  .cm-scroller {
    box-shadow: 0 0 20px 0 rgba(40, 58, 151, 0.2);
    border-radius: 1.5rem;
    font-family: monospace;
    font-size: 1.4em;
  }

  &__formula {
    height: 40rem;

    &__resize-btn {
      position: absolute;
      z-index: 1;
      bottom: 2.4rem;
      right: 2.4rem;
      border-radius: 0.2rem 0 1.5rem 0;
      padding: 0.4rem 0.5rem 0.5rem 0.4rem;
      &:hover {
        background-color: #e9eeff;
      }
    }
  }

  .cm-line {
    font-family: monospace;
    span {
      font-family: monospace;
    }
  }
}
