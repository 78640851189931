@import "src/app/styles/mixins/media-queries";

.info-block {
  padding: 3rem;

  @include tablet-max {
    padding: 1.5rem;
  }

  &__title {
    margin-bottom: 1.4rem;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.7rem;
    color: var(--black);
  }

  &__description {
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.8rem;
    color: var(--black);

    @include tablet-max {
      margin-bottom: 1.6rem;
      font-size: 1.4rem;
      line-height: 2.5rem;
    }

    &-link {
      color: $dark-blue;
      text-decoration: none;
      font-weight: bold;
    }
  }
}
