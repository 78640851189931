@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/variables/variables";

.fines-history-data-grid-wrapper {
  height: calc(100vh - 16.3rem);

  &-without-fines {
    display: flex;
    position: relative;
    top: 50%;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    color: $gray;
  }

  @include mobile-landscape {
    height: initial;
  }

  @include laptop {
    height: calc(100vh - 16.3rem);
  }
}
