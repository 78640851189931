@import "src/app/styles/variables/variables";
@import "src/app/styles/mixins/media-queries.scss";

.change-password {
  max-width: 39rem;
  width: 100%;

  @include tablet-max {
    max-width: 100%;
  }

  &__discription {
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $dark-gray;
  }

  &__control {
    margin-top: 2rem;
  }

  &__submit-btn {
    margin-top: 2.5rem;
  }
}
