@import "./media-queries";

@mixin container($x-padding: $gutter/2) {
  margin-left: auto;
  margin-right: auto;
  max-width: $container-width;
  padding: 0 $x-padding;
  width: 100%;
  position: relative;

  @include tablet() {
    max-width: $container-width-tablet;
  }

  @include laptop() {
    max-width: $container-width-laptop;
  }

  @include desktop() {
    max-width: $container-width-desktop;
  }

  @include desktop-large() {
    max-width: $container-width-desktop-large;
  }
}

@mixin flex(
  $align-items: flex-start,
  $justify-content: flex-start,
  $flex-wrap: nowrap,
  $flex-direction: row,
  $inline: false
) {
  @if ($inline == true) {
    display: inline-flex;
  } @else {
    display: flex;
  }

  align-items: $align-items;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
  justify-content: $justify-content;
}

@mixin row(
  $align-items: flex-start,
  $justify-content: flex-start,
  $flex-wrap: nowrap,
  $flex-direction: row,
  $x-margin: -$gutter/2,
  $inline: false
) {
  @include flex(
    $align-items: $align-items,
    $justify-content: $justify-content,
    $flex-wrap: $flex-wrap,
    $flex-direction: $flex-direction,
    $inline: $inline
  );

  margin-left: $x-margin;
  margin-right: $x-margin;
}

@mixin col($width: null, $x-margin: $gutter / 2, $x-padding: 0) {
  @if ($width) {
    @if ($x-margin == 0 or $x-margin == auto) {
      width: (100% / 12 * $width);
    } @else {
      width: calc(#{100% / 12 * $width} - #{$x-margin * 2});
    }
  } @else {
    width: auto;
  }

  margin-left: $x-margin;
  margin-right: $x-margin;
  padding-left: $x-padding;
  padding-right: $x-padding;
}
