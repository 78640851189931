@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/utils";

.base-card {
  @include flex(
    $flex-direction: column,
    $align-items: center,
    $justify-content: space-between
  );

  padding: 3rem;

  &__header {
    margin-bottom: 3rem;

    @include laptop {
      margin-bottom: 5rem;
    }
    &_input {
      margin-top: -0.5rem;
      position: relative;
      padding: 0.5rem;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
      border-radius: 0.6rem;
      background: none;
      border: none;
      color: $black;
      transition-property: background;
      transition-duration: 0.2s;
      transition-timing-function: ease;
      &:focus {
        outline: 1px solid $dark-blue;
      }
      &:hover {
        background: #e9eeff;
      }
    }
  }

  &__title {
    text-transform: uppercase;
    color: $black;
    word-break: break-word;
    text-align: center;
    font-weight: 800;
    font-size: 1.8rem;
    line-height: 1;
  }

  &__sub-title {
    color: $gray;
    font-size: 1.4rem;
    line-height: (19/14);
    text-align: center;
    margin-top: 1.4rem;
    font-weight: 400;
  }

  &__img {
    @include backgroundImage();

    min-width: 16rem;
    width: 16rem;

    @include laptop {
      min-width: 16rem;
      width: 16rem;
    }
  }

  &__footer {
    align-self: stretch;
    margin-top: 3.5rem;

    @include laptop {
      margin-top: 5rem;
    }
  }

  &__settings-button {
    margin-bottom: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;

    @include tablet {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    &:focus {
      box-shadow: 0 0 0 0.4rem lighten($button-primary-bg, 50%);
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 0.4rem lighten($button-primary-bg, 50%);
    }
  }
}
