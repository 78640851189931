@import "../../../app/styles/variables/variables";
@import "../../../app/styles/mixins/media-queries.scss";

.main-layout {
  margin: 0 auto;
  padding-top: 5.8rem;
  padding-bottom: 26.2rem;
  min-height: 120vh;
  //padding-left: $aside-bar-width;
  max-width: 100%;
  background-color: $page-wrapper-bg;
  display: flex;
  flex-direction: column;

  @include tablet-max {
    padding-left: 0;
    min-height: 100svh;
    padding-top: 0;
    padding-bottom: inherit;
  }

  @include mobile {
    padding-bottom: inherit;
    min-height: inherit;
  }
}
