@import "src/app/styles/mixins/media-queries";

.sessions-history-data-grid-wrapper {
  height: calc(100vh - 16.3rem);

  @include mobile-landscape {
    height: initial;
  }

  @include laptop {
    height: calc(100vh - 16.3rem);
  }

  &--for {
    &_player {
      height: calc(100vh - 34rem);

      @include laptop {
        height: calc(100vh - 24.3rem);
      }
    }
  }
}
