@import "src/app/styles/mixins/media-queries";

.number-item {
  display: flex;
  align-items: center;
  justify-content: center;

  &--communication {
    color: var(--blue);
  }

  &--analytics {
    color: var(--purple);
  }

  &--command {
    color: var(--sea-wave);
  }

  &--thinking3d {
    color: var(--pale-orange);
  }

  &--economics {
    color: var(--pink);
  }

  &--jurisprudence {
    color: var(--bright-green);
  }

  &--microelectronics {
    color: #316E99
  }

  &--current-lvl {
    width: 100%;
    height: 100%;
    background: currentColor;
    border: 0.5rem solid currentColor;
    border-radius: 100%;

    @include tablet-max {
      border-width: 0.4rem;
    }
  }

  &--integer {
    background: var(--white);
  }

  &__value {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: currentColor;

    &--current-lvl {
      font-size: 2.6rem;
      line-height: 3.5rem;

      @include tablet-max {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }
    }

    &--no-integer {
      color: var(--white);
    }

    &--next-lvl {
      color: var(--gray);
    }
  }
}
