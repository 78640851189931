@import "src/app/styles/variables/variables.scss";
@import "src/app/styles/mixins/utils";
@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";

.base-alert {
  @include flex($align-items: center, $justify-content: space-between);

  padding: 0.5rem 3rem;
  min-height: 8rem;

  @include mobile {
    padding: 0.5rem 1.5rem;
    min-height: 5.4rem;
    flex-direction: column;
  }

  &--small {
    min-height: auto;
    border-radius: 1rem;
    padding: 1.2rem 2.4rem;
    display: block;
  }

  &--warning {
    background: $light-orange;
  }

  &__message {
    font-size: 2rem;
    line-height: 2.7rem;
    display: flex;
    align-items: center;

    &::before {
      @include backgroundImage($background-size: contain);

      margin-right: 2rem;
      min-width: 3.2rem;
      width: 3.2rem;
      height: 3.2rem;
      content: "";
    }

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.6rem;

      &::before {
        margin-right: 1.2rem;
        min-width: 3rem;
        width: 3rem;
        height: 3rem;
      }
    }

    &--warning {
      color: $orange;
      font-weight: 600;

      &::before {
        background-image: url("../../images/icons/alert-triangle-orange.svg");
      }
    }

    &--small {
      font-size: 1.2rem;

      &::before {
        width: 1.2rem;
        height: 1.2rem;
        min-width: auto;
        margin-right: 0.5rem;
      }
    }
  }

  &__slot {
    margin-left: 1rem;

    @include mobile {
      margin-top: 1rem;
      width: 100%;
    }
  }
}
