@import "src/app/styles/mixins/utils";

.list-checkbox {
  padding: 0.9rem 1.5rem 0.9rem 1.2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:not(:last-child) {
    box-shadow: inset 0 -0.1rem 0 #dee1e9;
  }

  &__input {
    display: none;
  }

  &__label {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: var(--black);
  }

  &--active {
    &::after {
      @include backgroundImage();

      margin-left: 1rem;
      content: "";
      min-width: 0.9rem;
      width: 0.9rem;
      height: 0.9rem;
      background-image: url("../../../../../shared/images/icons/icon-check-blue.svg");
    }
  }

  &--active &__label {
    color: var(--dark-blue);
  }
}
