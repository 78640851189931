.competence-control-list {
  margin-top: 2rem;

  &__item {
    &:not(:last-child) {
      margin-bottom: 1.4rem;
    }

    &--with-tenacity:not(:last-child) {
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 0.1rem solid var(--extra-light-gray);
    }

    &-competence {
      &--tenacity {
        margin-top: 1.4rem;
      }
    }
  }
}
