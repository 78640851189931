@import "src/app/styles/mixins/media-queries";

.edit-countries-page {
  height: calc(100vh - 20.2rem);

  @include mobile-landscape {
    height: initial;
  }

  @include tablet-landscape {
    height: initial;
  }

  @include laptop {
    height: calc(100vh - 21rem);
  }
}
