@import "src/app/styles/mixins/media-queries";

.contacts-page {
  width: 100%;
  max-width: 124rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  @include desktop-max {
    grid-template-columns: 1fr;
  }

  &__item {
    &--large {
      grid-column: 1/3;

      @include desktop-max {
        grid-column: auto;
      }
    }
  }
}
