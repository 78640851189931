@import "src/app/styles/mixins/media-queries";
@import "src/entities/public/notifications/ui/notification-card/variables.scss";

.tile-on-page {
  position: fixed;
  bottom: $gap;
  right: $gap;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $gap;
  z-index: 999;

  @include mobile() {
    top: 6.5rem;
    bottom: auto;
    right: auto;
    gap: 0;
    width: 100%;
  }

  &__hide-button {
    @extend .base-notification-on-page-card;

    @include mobile() {
      display: none;
    }
  }
}
