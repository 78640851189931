@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";

.users-control-panel-btn {
  @include flex($align-items: center);

  &__link {
    text-decoration: none;
  }

  &__container {
    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    &--upload {
      margin-left: 3rem;
      margin-right: 5rem;

      @include tablet-max {
        margin-left: 1.5rem;
        margin-right: 3rem;
      }
    }
  }
}
