@import "src/app/styles/variables/variables";

.recipients-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  overflow: inherit;

  &__base-box {
    min-width: 41rem;
    margin-bottom: 3rem;
    min-height: 20rem;
    display: grid;
    grid-area: 5;

    &-pick-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.5rem;
      font-size: 1.6rem;
    }
  }

  &__radio {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    align-items: center;
    margin: 2.5rem 0 2.5rem 0;
    font-size: 1.3rem;
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid $gray;

    :first-child {
      margin-right: 3rem;
    }
  }
}
