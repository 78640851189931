.game-type-card {
  display: flex;
  flex-direction: column;

  padding: 2rem;
  background: var(--lightest-gray);
  border-radius: var(--border-radius-large);
  gap: 2rem;
  margin-right: 0.4rem;

  .game-type-card__container-wrapper--divided {
    border-bottom: 0.1rem solid var(--extra-light-gray);

    .competence-control-list__item-competence {
      margin-bottom: 2rem;
    }
  }

  &--disabled {
    filter: opacity(0.6);
    cursor: not-allowed;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }
}
