@import "src/app/styles/mixins/media-queries";

@import "src/app/styles/mixins/utils";

.level-up-list {
  width: 100%;
  background: #ededf3;
  padding: 4rem 0 4rem 4rem;
  border-right: 4rem solid;
  border-color: #ededf3;

  @include mobile {
    width: auto;
    border-right: 0;
    padding: 3rem 2rem;
  }

  .swiper-wrapper {
    display: flex;
    align-items: stretch;
  }

  .swiper-button {
    &-prev,
    &-next {
      min-width: 10rem;
      width: 10rem;
      height: 100%;
      position: fixed;
      top: 0;
      background: rgba(40, 58, 151, 0.1);
      backdrop-filter: blur(0.5rem);
      z-index: 10;
      transition: 0.3s;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        @include backgroundImage($background-size: contain);

        background-image: url("../../../../../shared/images/icons/arrow-blue.svg");
        display: block;
        content: "";
        min-width: 2.5rem;
        width: 2.5rem;
        height: 2.5rem;
      }

      &:hover {
        opacity: 1;
      }

      @include tablet-max {
        display: none;
      }
    }

    &-prev {
      transform: rotate(180deg);
      left: 0;

      @include tablet-max {
        left: 0;
      }
    }

    &-next {
      right: 0;
    }

    &-disabled,
    &-lock {
      display: none;
    }
  }

  &__item {
    max-width: 45rem;
    height: auto;

    @include mobile {
      transform: scale(0.9);
      transition: 0.3s ease-in-out;

      &.swiper-slide-active {
        transform: scale(1);
      }
    }
  }
}
