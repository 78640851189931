@import "src/app/styles/mixins/grid";

.formula-info-popup {
  max-width: 62rem;

  &-descriptions {
    margin-bottom: 3.6rem;
  }

  &__description {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: $dark-gray;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &-item {
      &-strong {
        font-family: monospace;
      }
    }

    &-list {
      margin-top: 1rem;
      padding-left: 2rem;
      list-style: none;

      &-item {
        position: relative;

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }

        &::before {
          min-width: 0.4rem;
          width: 0.4rem;
          height: 0.4rem;
          content: "";
          display: block;
          background: $dark-gray;
          border-radius: 100%;
          position: absolute;
          top: 1.1rem;
          left: -1.5rem;
        }
      }
    }

    &-formula {
      margin-top: 1rem;
    }
  }

  &-examples {
    &__title {
      margin-bottom: 2.6rem;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $black;
      text-align: center;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }

      &-title {
        margin-bottom: 1.2rem;
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: $black;
      }
    }
  }
}
