@import "src/app/styles/mixins/media-queries.scss";

.email-mailing-lists-page {

  height: calc(100vh - 26.1rem);

  @include mobile-landscape {
    height: initial;
  }

  @include tablet-landscape {
    height: initial;
  }

  @include laptop {
    height: calc(100vh - 29rem);
  }
  .notification-text-ellipsis .ql-editor p {
    width: 35rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }
}

