@import "src/app/styles/mixins/grid";

.team-roles-select {
  @include flex($align-items: center);

  width: 100%;

  &--offset {
    margin-left: 0.9rem;
  }
}
