@import "src/app/styles/mixins/media-queries.scss";

.edit-faq-page {
  max-width: 56.7rem;

  &__mobile-save-btn {
    margin-top: 3rem;
    display: none;

    @include tablet-max {
      display: block;
    }
  }

  &__desktop-save-btn {
    @include tablet-max {
      display: none;
    }
  }
}
