@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/media-queries";

.listLength {
  color: $neutral-n-100;
  font-size: $font-size-3;
  font-weight: 400;
  text-decoration-line: underline;
  margin-left: .8rem;

  &:hover {
    color: $blue-b-100;
  }
}

.table {
  &.unassigned {
    @include flex(flex-start, initial);
    gap: 2.4rem;
    width: 100%;
    margin: 0;

    @include tablet-max {
      overflow-x: scroll;
    }

    .section {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      height: 100%;

      .sectionHeader {
        @include flex(initial, space-between);

        color: $neutral-n-300;
        font-size: $font-size-6;
        font-weight: 700;
        line-height: 140%;

        .buttons {
          @include flex(center);
          gap: 1.6rem;
        }

        .headerButton {
          padding: .8rem 2.4rem;
          font-size: $font-size-2;
          display: flex;
          gap: .8rem;
          height: 3.4rem;

          &.collapsed {
            width: 3.4rem;
            padding: .8rem;
          }
        }
      }

      .footerButtons {
        display: flex;
        margin-top: 2.4rem;
        gap: 1.6rem;

        & > * {
          flex: 1;
        }
      }
    }

    .table {
      border-radius: $border-radius;
      overflow: hidden;
      box-shadow: 0 0 20px 0 rgba(40, 58, 151, 0.20);
      @include flex(initial, flex-start, nowrap, column);

      .tableHeader {
        font-size: $font-size-4;
        line-height: 3.2rem;
        font-weight: 700;
        background: $blue-b-10;
        padding: 1.2rem 2rem;

        @include flex(center, space-between);

        &.isOver {
          background: $neutral-n-50 ;
        }
      }

      .tableContent {
        background: $neutral-n-0;
        height: 100%;
        overflow-y: scroll;
        padding: 2rem;

        @include flex(initial, flex-start, nowrap, column);
        gap: 1.2rem;

        &.empty {
          @include flex(center, center);
          font-size: $font-size-3;
          color: $neutral-n-100;
        }
      }
    }

    .playersListTable {
      height: 100%;
    }

    .teamAccordion {
      border-radius: $border-radius;
      box-shadow: 0 0 20px 0 rgba(40, 58, 151, 0.20);

      h2 {
        padding: 1.2rem 2rem;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      &.empty h2 {
        border-radius: $border-radius;
      }
    }

    .corpAccordionContent {
      @include flex($flex-direction: column);
      gap: 2rem;
      padding: 2rem;
    }

    .accordionHeaderContent {
      font-size: $font-size-4;
      height: 3.2rem;
      width: 100%;
      margin-left: .8rem;
      @include flex(center, flex-start);
    }

    .accordionHeaderCross {
      margin-left: auto;
      padding: 0;
    }

    .teamsList {
      @extend .section;

      padding: 2rem;
      margin: -2rem -2rem 0 -2rem;

      overflow-y: scroll;

      &.empty {
        align-items: center;
        justify-content: center;

        p {
          font-size: $font-size-3;
          color: $neutral-n-100;
        }
      }
    }
  }

  & > div {
    width: 100%;
  }
}
