@import "src/app/styles/mixins/media-queries";

.templates-block {
  grid-column: 2/9;
  grid-row: 7/10;
  max-width: 660px;

  @include desktop-max {
    width: 100%;
    overflow: auto;
    max-width: initial;
    order: 1;
  }

  &__header {
    padding: 2.4rem 2.4rem 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      min-width: 52rem;
      padding: 2.4rem 2rem 2rem;
    }

    &-title {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $color-26;
      white-space: nowrap;
    }
  }

  &__grid {
    &-edit-btn {
      padding: 0.6rem 0;

      @include mobile {
        padding: 1.3rem 0;
      }
    }
  }

  &__grid.data-grid {
    overflow: hidden;

    @include mobile {
      min-width: 52rem;
      max-width: none;
    }
  }
}
