@import "src/app/styles/mixins/media-queries";

.change-history-page {

  &-data-grid-wrapper {
    height: calc(100vh - 10rem);

    @include mobile-landscape {
      height: initial;
    }

    @include tablet-landscape {
      height: initial;
    }
  }


  .base-panel {
    padding: 0;
  }

  .control-panel {
    padding: 0;
  }

  .page-wrapper__container {
    padding: 2.4rem 3rem 0 3rem;
  }
}