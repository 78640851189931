@import "src/app/styles/variables/variables";

.validate-lobby-helper {
  background-color: $extra-light-purple;
  box-shadow: 0 0 20px 0 rgba(40, 58, 151, 0.3);
  border-radius: 1.6rem;
  font-size: 14px;
  font-weight: 600;
  animation: bounce-in 0.5s ease-in-out;
  padding: 1.5rem;
  margin-top: 4rem;
  position: fixed;
  display: flex;
  color: $dark-blue;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  z-index: 1;
  text-align: center;
  white-space: pre-wrap;

  &_message {
    margin-bottom: 1rem;
  }

  &-title {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  &-icon {
    margin-right: initial;
  }

  &_close {
    padding: 0.5rem;
  }

  &__is_closed {
    animation: bounce-out 0.5s ease-in-out;
  }

  &__is_ready {
    background-color: $light-green;
    color: $green;
    box-shadow: 0 0 20px 0 rgba(0, 208, 133, 0.3);
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(0);
  }
}
