@import "../../../../app/styles/mixins/grid";
@import "../../../../app/styles/mixins/media-queries";
@import "../../../../app/styles/mixins/utils";
@import "../../../../app/styles/mixins/font";

.field {
  position: relative;
  display: block;
  font-size: 1.4rem;
  width: 100%;
  cursor: text;

  &--type {
    &_date {
      overflow: hidden;
    }
  }

  &--with-trangle {
    &:after {
      position: absolute;
      top: 2.5rem;
      right: 1.5rem;
      content: "";
      @include backgroundImage();
      width: 1rem;
      height: 0.6rem;
      background-image: url("../../../images/icons/icon-trangle-blue.svg");
    }
  }

  &--open {
    &:after {
      transform: rotate(180deg);
    }
  }

  &--disabled {
    pointer-events: none;
    //убрать это потом
    &.field--with-trangle:after {
      display: none;
    }

    .field__input {
      font-size: 1.8rem;
      padding: 0.9rem 0 1.2rem 0.9rem !important;
      border: none;
      text-align: right;
    }
  }

  &--hide-label {
    span {
      display: none;
    }

    .field__input {
      font-size: 1.8rem;
      padding: 0.9rem 0 1.2rem 0.9rem !important;
      border: none;
    }
  }

  &__input,
  &__textarea {
    font-size: inherit;
    line-height: inherit;
    color: $black;
    cursor: inherit;
    display: block;
    background: transparent;
    height: $field-height;
    padding: 2.4rem 1.2rem 0.9rem;
    width: 100%;
    outline: none;
    border: 0.1rem solid $extra-light-gray;
    border-radius: $border-radius-small;
    -webkit-appearance: none;
    transition: opacity 0.2s ease;

    &:focus {
      border-color: $dark-blue;
    }

    &[readonly] {
      background: $lightest-gray;
      border-color: $lightest-gray;
    }

    &:disabled {
      cursor: not-allowed;
      color: $dark-gray;
      opacity: 0.5;
    }

    &--size {
      &_small {
        height: var(--field-height-medium);
        padding: 0.8rem 1.2rem;
      }
    }

    &--for-dark-bg {
      border-color: $dark-gray;
      color: $white;

      &:focus {
        border-color: $white;
      }

      &:-webkit-autofill {
        background-clip: text;
        -webkit-text-fill-color: $white;
      }
    }

    &--error {
      border-color: $red;

      &:focus {
        border-color: $red;
      }
    }

    &--type {
      &_date {
        &::-webkit-calendar-picker-indicator {
          width: auto;
          height: auto;
          background: transparent;
          color: transparent;
          left: -100%;
          right: 0;
          top: 0;
          bottom: 0;
          cursor: pointer;
          position: absolute;
          outline: 0;
        }
      }

      &_time {
        &::-webkit-date-and-time-value {
          text-align: left;
        }
      }

      &_time,
      &_date {
        overflow: hidden;
        font-size: 0;

        &:focus,
        &-has-value {
          font-size: inherit;
        }
      }
    }

    &--with-btn {
      padding-right: $field-height;
    }
  }

  &__textarea {
    padding: 1.2rem;
    resize: none;

    &--size {
      &_default {
        min-height: 10rem;
      }

      &_small {
        min-height: 8.4rem;
      }

      &_medium {
        min-height: 40rem;
      }
    }

    &--monospace {
      font-family: monospace;
    }

    &--resize {
      resize: vertical;
    }

    &-label {
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: var(--gray);
      position: absolute;
      top: 1.2rem;
      left: 1.2rem;
    }

    &--collapsed + &-label {
      display: none;
    }
  }

  &__label {
    padding: 0 0.5rem 0 1.2rem;
    width: 100%;
    font-size: 1.4rem;
    color: $gray;
    position: absolute;
    top: 1.7rem;
    left: 0;
    transition: $transition;
    transform-origin: top left;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @at-root .field__input:focus:not([readonly]) + &,
      &--collapsed {
      transform: translateY(-1rem);
      font-size: 1.2rem;

      &.field__label--for-size {
        &_small {
          font-size: inherit;
          transform: scaleX(0);
          opacity: 0;
        }
      }
    }

    &--for-size {
      &_small {
        top: 0.8rem;
      }
    }

    &--for-dark-bg {
      color: $gray;
    }

    &--with-btn {
      width: calc(100% - #{$field-height});
    }
  }

  &__delete-btn {
    position: absolute;
    right: 0;
    top: 0;
    width: $field-height;
    height: $field-height;
  }

  &__copy-btn {
    position: absolute;
    right: 0.6rem;
    top: 0.9rem;
    width: 2.4rem;
    height: 2.4rem;
  }

  &__refresh-btn {
    min-width: 3.8rem;
    width: 3.8rem;
    height: 3.8rem;
    position: absolute;
    background: $extra-light-purple;
    border-radius: $border-radius-small;
    right: 1.2rem;
    top: calc((#{$field-height} - 3.8rem) / 2);
  }

  &__refresh-btn,
  &__delete-btn {
    &:disabled {
      cursor: not-allowed;
      filter: grayscale(1);
    }
  }

  &__eye {
    position: absolute;
    top: 2.1rem;
    right: 2.1rem;
    width: 1.9rem;
    height: 1.5rem;
    @include backgroundImage();

    &--open {
      background-image: url("../../../images/icons/icon-eye-open-blue.svg");
    }

    &--close {
      background-image: url("../../../images/icons/icon-eye-close-blue.svg");
    }
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
