@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/utils";
.popup {
  &__email-verify {
    max-width: 32.8rem;
    @include flex(
            $align-items: center,
            $justify-content: center,
            $flex-direction: column
    );

    &__circle {
      @include flex($align-items: center, $justify-content: center);

      width: 4rem;
      height: 4rem;
      background-color: $color-18;
      border-radius: 50%;
    }

    &__icon {
      @include backgroundImage($background-size: contain);

      width: 2rem;
      height: 1.6rem;
      background-image: url("../../../../shared/images/icons/mail-blue.svg");
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.2rem;
      margin: 1.5rem 0 1rem 0;
    }

    &__text {
      margin-top: 0.5rem;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.9rem;
      text-align: center;
    }

    &__email {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 1.9rem;
      text-align: center;
      color: $dark-blue;
      margin-top: 0.5rem;
    }
  }
}