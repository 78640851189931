@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils";

.base-banner {
  @include flex($align-items: center, $justify-content: center);
  margin: 4rem 0;
  min-height: 8rem;
  border-radius: 2rem;
  width: 100%;
  position: relative;

  @include tablet-max {
    padding: 2.4rem;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }

  &--first {
    background: #6c59e0;
    box-shadow: 0 0 2rem 0 rgba(108, 89, 224, 0.2);
  }

  &--second {
    background: #0083ca;
    box-shadow: 0 0 2rem 0 rgba(0, 131, 202, 0.2);

    &::before,
    &::after {
      @include backgroundImage();

      content: "";
      display: block;
      width: 30rem;
      height: 100%;
      position: absolute;
      top: 0;

      @include tablet-max {
        display: none;
      }
    }

    &::before {
      background-image: url("../../images/pixels-one.svg");
      left: 0;
    }

    &::after {
      background-image: url("../../images/pixels-two.svg");
      right: 0;
    }
  }

  &--third {
    background: #badff2;
    box-shadow: 0 0 2rem 0 rgba(40, 58, 151, 0.2);
  }

  &__img {
    height: 8rem;

    @include tablet-max {
      display: none;
    }

    &--first {
      width: 14.6rem;
      background-image: url("../../images/banner-elon.png");

      @include tablet-max {
        background-size: contain;
        background-position: 35vw bottom;
        opacity: 0.5;
      }
    }

    &--second {
      width: 20.8rem;
      background-image: url("../../images/banner-hands.png");
    }

    &--third {
      width: 10rem;
      margin-right: 3.2rem;
      background-image: url("../../images/banner-money.svg");
    }
  }

  &__info {
    margin-right: 2.4rem;
    z-index: 2;

    @include tablet-max {
      margin: 0 0 2rem;
    }

    &-title {
      font-size: 2.4rem;
      font-weight: 800;

      @include tablet-max {
        font-size: 2rem;
      }

      &--first {
        font-size: 1.9rem;
        color: $white;
      }

      &--second {
        color: $white;
      }

      &--third {
        color: $black;
      }
    }

    &-description {
      margin-top: 0.2rem;
      font-size: 1.6rem;
      font-weight: 600;

      &--second {
        color: #97daf8;
      }

      &--third {
        color: #3b3c42;
      }
    }
  }

  &__controls {
    position: relative;
    z-index: 1;

    @include tablet-max {
      width: 100%;
    }
  }

  &__btn {
    height: auto;
    padding: 1.2rem 2.4rem;

    @include tablet-max {
      width: 100%;
    }

    &--first {
      background: $white;
      color: #5038e1;
    }

    &--second {
      background: $white;
      color: $color-29;
    }

    &--third {
      background: $dark-blue;
      color: $white;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  &__more-info {
    @include backgroundImage($background-size: 110%);
    @include flex($align-items: center, $justify-content: center);

    min-width: 3rem;
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: calc(100% + 1.5rem);
    top: 1rem;
    z-index: 1;
    background-color: $white;
    border-radius: 100%;
    font-size: 2rem;
    text-decoration: none;
    color: $black;
    font-weight: bold;

    &--first {
      background: $white;
      color: #5038e1;
    }

    &--second {
      background: $white;
      color: $color-29;
    }

    &--third {
      background: $green;
      color: $white;
    }
  }
  &__left_wrapper {
    display: flex;
    align-items: center;

    @include tablet-max {
      width: 100%;
    }
  }
  &__question-mark {
    margin-left: 1.6rem;
    height: 3.2rem;
    width: 3.2rem;
    background: $dark-blue;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    text-decoration: none;
    font-weight: 700;

    @include tablet-max {
      width: 100%;
      height: 100%;
      max-width: 3.2rem;
      max-height: 3.2rem;
    }

    &__first {
      background: $white;
      color: #6c59e0;
    }

    &__second {
      background: $white;
      color: $color-29;
    }
  }
}
