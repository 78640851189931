@import "src/app/styles/mixins/media-queries";

.roles-block {
  &__item {
    &:not(:last-child) {
      margin-bottom: 2.6rem;
    }

    &-title {
      margin-bottom: 0.8rem;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $black;
    }

    &-description {
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $black;
    }

    &-checkboxes {
      display: flex;
      gap: 8px;
      flex-direction: column;
    }
  }
}
