@import "../mixins/media-queries";
:root {
  --aside-bar-width: #{$aside-bar-width};
  --aside-bar-small-width: #{$aside-bar-small-width};
  --header-height: #{$header-height};
  --field-height: #{$field-height};
  --field-height-medium: #{$field-height-medium};
  --field-height-small: #{$field-height-small};

  --color-6: #{$color-6};
  --color-15: #{$color-15};
  --color-16: #{$color-16};
  --color-18: #{$color-18};
  --color-21: #{$color-21};
  --color-22: #{$color-22};
  --color-23: #{$color-23};
  --color-24: #{$color-24};
  --color-25: #{$color-25};
  --color-26: #{$color-26};
  --color-28: #{$color-28};
  --color-29: #{$color-29};

  --white: #{$white};
  --black: #{$black};
  --dark-gray: #{$dark-gray};
  --gray: #{$gray};
  --extra-light-gray: #{$extra-light-gray};
  --lightest-gray: #{$lightest-gray};
  --dark-blue: #{$dark-blue};
  --blue: #{$blue};
  --light-blue: #{$light-blue};
  --purple: #{$purple};
  --light-purple: #{$light-purple};
  --extra-light-purple: #{$extra-light-purple};
  --sea-wave: #{$sea-wave};
  --green: #{$green};
  --light-green: #{$light-green};
  --red: #{$red};
  --light-red: #{$light-red};
  --orange: #{$orange};
  --light-orange: #{$light-orange};
  --extra-light-orange: #{$extra-light-orange};
  --pale-orange: #{$pale-orange};
  --pink: #{$pink};
  --bright-green: #{$bright-green};

  --competence-scale: #{$competence-scale};
  --competence-scale-bg: #{$competence-scale-bg};
  --scale-bg-in-menu: #{$scale-bg-in-menu};
  --buttons-fill: #{$buttons-fill};
  --hover-buttons-fill: #{$hover-buttons-fill};
  --scroll-slider: #{$scroll-slider};
  --tooltips-bg: #{$tooltips-bg};
  --tooltips-bg-no-blur: #{$tooltips-bg-no-blur};

  --border-radius-large: #{$border-radius-large};
  --border-radius: #{$border-radius};
  --border-radius-small: #{$border-radius-small};
  --border-radius-smallest: #{$border-radius-smallest};

  --transition: #{$transition};

  /* GRID */
  --gutter: #{$gutter};

  --container-width: #{$container-width};
  --container-width-tablet: #{$container-width-tablet};
  --container-width-laptop: #{$container-width-laptop};
  --container-width-desktop: #{$container-width-desktop};
  --container-width-desktop-large: #{$container-width-desktop-large};

  --mobile-small-max-width: #{$mobile-small-max-width};
  --tablet-min-width: #{$tablet-min-width};
  --laptop-min-width: #{$laptop-min-width};
  --desktop-min-width: #{$desktop-min-width};
  --desktop-large-min-width: #{$desktop-large-min-width};
  --desktop-x-large-min-width: #{$desktop-x-large-min-width};
  --desktop-xx-large-min-width: #{$desktop-xx-large-min-width};
  --desktop-extra-large-min-width: #{$desktop-extra-large-min-width};

  /* Z-INDEX */
  --z-index-page-wrapper: 100;
  --z-index-aside-bar: 200;
  --z-index-header: 300;
  --z-index-base-notification: 400;
  --z-index-base-snackbar: 900;
  --z-index-base-popup: 600;
  --z-index-base-tooltip: 700;
  --z-index-notifications-panel: 800;

  /* BUTTONS */
  --button-default-bg: var(--dark-blue);

  --button-primary-bg: var(--dark-blue);
  --button-primary-border: var(--dark-blue);
  --button-primary-text-color: var(--white);

  --button-secondary-bg: var(--white);
  --button-secondary-border: var(--scale-bg-in-menu);
  --button-secondary-text-color: var(--scale-bg-in-menu);

  --button-light-bg: var(--black);
  --button-light-text-color: var(--blue);

  --button-disabled-bg: var(--color-15);
  --button-disabled-border: var(--color-15);
  --button-disabled-text-color: var(--white);

  /* FONTS */
  --font-size-1: #{$font-size-1};
  --font-size-2: #{$font-size-2};
  --font-size-3: #{$font-size-3};
  --font-size-4: #{$font-size-4};
  --font-size-5: #{$font-size-5};
  --font-size-6: #{$font-size-6};
  --font-size-7: #{$font-size-7};
  --font-size-8: #{$font-size-8};
  --font-size-9: #{$font-size-9};
  --font-size-10: #{$font-size-10};

  /* SHADOW-FOR-CARDS */
  --shadow-for-carts: #{$shadow-for-carts};
}
