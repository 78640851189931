@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/font";
@import "src/app/styles/mixins/utils";

.base-error {
  @include flex();

  margin: 0.4rem 0;
  @include set-font(null, 1.2rem, null, 600);

  &--danger {
    color: $red;
  }

  &--warning {
    color: $color-21;
  }

  &--info {
    color: $dark-blue;
  }

  &--description {
    color: $gray;
  }

  &__icon {
    @include backgroundImage($background-size: contain);

    display: inline-block;
    min-width: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.2rem 0.6rem 0 0;

    &--danger {
      background-image: url("../../../shared/images/icons/icon-danger-triangle.svg");
    }

    &--warning {
      background-image: url("../../../shared/images/icons/icon-warning-triangle.svg");
    }

    &--info {
      background-image: url("../../../shared/images/icons/icon-info-triangle.svg");
    }

    &--description {
      display: none;
    }
  }
}
