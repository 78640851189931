@import "src/app/styles/mixins/grid.scss";

.empty-block {
  // max-width: 40rem;
  width: 100%;

  &-wrap {
    @include flex($align-items: center, $justify-content: center);

    flex-grow: 1;
    width: 100%;
    height: 100%;
  }

  &__title {
    margin-bottom: 1.5rem;
    text-align: center;
    font-weight: 800;
    font-size: 2.2rem;
    line-height: 3.2rem;
    color: $black;
  }

  &__description {
    text-align: center;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.6rem;
    color: $dark-gray;
  }
}
