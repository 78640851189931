@import "src/app/styles/variables/variables.scss";

.button {
  display: flex;
  align-items: center;
  padding: 0.7rem;
  border-radius: 50%;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transition: background-color 0.2s ease;
  }
}
