@import "../../../app/styles/variables/variables.scss";
@import "../../../app/styles/mixins/grid.scss";

.statistics {
  background: $neutral-n-0;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0px 0px 20px 0px rgba(40, 58, 151, 0.2);

  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 26px */
    margin-bottom: 20px;
  }

  h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 20.8px */
    margin-bottom: 12px;
    margin-top: 20px;
  }
}

.time_selectors {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;

  > * {
    flex: 1;
  }
}

.chart {
  height: 12px;
  width: 100%;
  border-radius: 100px;
  margin-bottom: 12px;
}

.tags {
  @include flex(flex-start, flex-start, nowrap, column);
  gap: 8px;
  list-style: none;
}

.tag {
  border-radius: 8px;
  border: 1px solid $neutral-n-50;
  padding: 8px 12px;
  @include flex(center, center);
  gap: 8px;

  &_name {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
  }

  &_count {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 18.2px */
  }

  &_mark_green {
    height: 12px;
    width: 12px;
    border-radius: 12px;
    background: $green-g-40;
  }

  &_mark_red {
    @extend .tag_mark_green;
    background: $red-r-100;
  }
}

.datepicker {
  border-radius: $border-radius-small;
  height: $field-height;
  padding: 4px 12px;
  @include flex(center, flex-start);
  border: 1px solid $neutral-n-50;
  cursor: pointer;

  &_placeholder {
    font-size: 14px;
    color: #8b8fa4;
    transition: 0.3s;
    position: absolute;
    user-select: none;
  }

  &_input {
    width: 100%;
    font-size: 14px;
    color: $neutral-n-300;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  & > div {
    align-self: flex-end;
  }

  &:has(input:focus),
  &:has(input:valid) {
    .datepicker_placeholder {
      font-size: 12px;
      transform: translate(0, -12px);
    }
  }
}
