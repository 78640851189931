@import "src/app/styles/mixins/media-queries";

.grid-column-item {
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  @include mobile {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &--no-padding-left {
    padding-left: 0;
  }

  &--no-padding-right {
    padding-right: 0;
  }
}
