$aside-bar-width: 34rem;
$aside-bar-small-width: 28rem;
$header-height: 6rem;
$field-height: 5.6rem;
$field-height-medium: 3.6rem;
$field-height-small: 3rem;

$color-6: #e0e4f8;
$color-15: #c8cce1;
$color-16: #b9e5fb;
$color-18: #d9f1fd;
$color-21: #f2c94c;
$color-22: #f2fbff;
$color-23: #f9f9f9;
$color-24: #ff8a00;
$color-25: #e2e2e2;
$color-26: #1e264d;
$color-28: #eef0f7;
$color-29: #005dac;

$white: #ffffff;
$black: #1a1a1a;
$dark-gray: #63677c;
$gray: #8b8fa4;
$extra-light-gray: #d4daec;
$lightest-gray: #f8fafb;
$dark-blue: #283a97;
$blue: #00aeef;
$light-blue: #00e0ff;
$purple: #6e56ff;
$pale-orange: #fca21a;
$pink: #fc1ab4;
$bright-green: #43d000;
$light-purple: #a4b1de;
$extra-light-purple: #e9eeff;
$sea-wave: #00c4d0;
$green: #00d085;
$light-green: #b9f8d6;
$red: #ff3d3d;
$light-red: #ffc9c9;
$orange: #ff9900;
$light-orange: #ffedca;
$extra-light-orange: #fff9ed;
$teal: #316e99;

$neutral-n-0: #fff;
$neutral-n-10: #f8fafb;
$neutral-n-20: #303034;
$neutral-n-30: #f4f6ff;
$neutral-n-40: #e9eeff;
$neutral-n-50: #d4daec;
$neutral-n-100: #8b8fa4;
$neutral-n-300: #1a1a1a;

$blue-b-10: #e9eeff;
$blue-b-100: #283a97;

$red-r-10: #fff9f9;
$red-r-20: #ffebeb;
$red-r-30: #ffc9c9;
$red-r-40: #ff7070;
$red-r-100: #ff3d3d;

$green-g-40: #00d085;

$competence-scale: #97daf8;
$competence-scale-bg: #ebf8ff;
$scale-bg-in-menu: #2a4eb5;
$buttons-fill: linear-gradient(91.39deg, #283a97 0.76%, #0157a0 100.11%);
$hover-buttons-fill: linear-gradient(91.39deg, #32449c 0.15%, #105ea5 100.11%);
$scroll-slider: #c6cce2;
$tooltips-bg: rgba(46, 46, 46, 0.9);
$popover-bg: rgba(46, 46, 46, 0.9);
$tooltips-bg-no-blur: rgba(39, 39, 39, 0.95);
$page-wrapper-bg: #ededf3;

$border-radius-large: 2rem;
$border-radius: 1.2rem;
$border-radius-small: 0.8rem;
$border-radius-smallest: 0.4rem;

$transition: all 100ms linear;

/* GRID */
$gutter: 3rem;

$container-width: 100%;
$container-width-tablet: 76.8rem;
$container-width-laptop: 102.4rem;
$container-width-desktop: 124.8rem;
$container-width-desktop-large: 136.2rem;

$mobile-small-max-width: 320px;
$mobile-medium-min-width: 576px;
$tablet-min-width: 768px;
$laptop-min-width: 1024px;
$desktop-min-width: 1248px;
$desktop-large-min-width: 1366px;
$desktop-x-large-min-width: 1440px;
$desktop-xx-large-min-width: 1600px;
$desktop-extra-large-min-width: 1921px;

/* BUTTONS */
$button-default-bg: $dark-blue;

$button-primary-bg: $dark-blue;
$button-primary-border: $dark-blue;
$button-primary-text-color: $white;

$button-secondary-bg: $white;
$button-secondary-border: $scale-bg-in-menu;
$button-secondary-text-color: $scale-bg-in-menu;

$button-light-bg: $black;
$button-light-text-color: $blue;

$button-disabled-bg: $color-15;
$button-disabled-border: $color-15;
$button-disabled-text-color: $white;

/* FONTS */
$font-size-1: 1.2rem;
$font-size-2: 1.4rem;
$font-size-3: 1.6rem;
$font-size-4: 1.8rem;
$font-size-5: 2rem;
$font-size-6: 2.4rem;
$font-size-7: 2.6rem;
$font-size-8: 2.8rem;
$font-size-9: 3rem;
$font-size-10: 19rem;

/* SHADOWS */

$shadow-for-carts: 0 0 2rem 0 rgba(40, 58, 151, 0.2);
