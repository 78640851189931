@import "src/app/styles/mixins/media-queries";

.select-players-buttons {
  display: flex;
  justify-content: flex-end;

  & > *:first-child {
    margin: 0 1rem;
  }
  & > *:last-child {
   margin-left: 1rem;
  }
}

.select-players-page {
  height: calc(100vh - 34rem);

  @include mobile-landscape {
    height: initial;
  }

  @include tablet-landscape {
    height: initial;
  }

  @include laptop {
    height: calc(100vh - 24.3rem);
  }
}
