@import "src/app/styles/mixins/grid";

@import "src/app/styles/mixins/media-queries";

@import "src/app/styles/mixins/utils";

.session-page {
  @include flex($align-items: center, $justify-content: center);

  padding: 4rem 0 15rem 0;
  background: $page-wrapper-bg;
  min-height: 100svh;

  &-hr-created {
    height: 100svh;
    padding: 2rem 3rem 12rem 3rem;
    min-height: 100svh;
    align-items: initial;
  }

  @include tablet-max {
    padding: 3rem 2rem;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__timer {
    &-title {
      font-size: 2rem;
      font-weight: 400;
      line-height: 2rem;
      text-align: center;

      @include tablet-max {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: 1rem;
      }
    }
    &-clock {
      font-size: 2.2rem;
      font-weight: 400;
      line-height: 2rem;
      color: $black;
      text-align: center;
      margin: 1.5rem 0;

      @include tablet-max {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.session {
  width: 58rem;

  &-hr {
    display: flex;
    padding: 3rem 2rem 0 2rem;
    flex-direction: column;
    width: 100%;

    &__header {
      display: flex;
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 3rem;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;

      &-item {
        display: flex;
        justify-content: space-between;

        &_members {
          display: flex;
          justify-content: space-between;
          margin-left: 3rem;
        }

        &_length {
          margin-left: 0.8rem;
          font-weight: 400;
          font-size: 16px;
          color: #8b8fa4;
        }
      }

      &-buttons {
        white-space: nowrap;

        button,
        a {
          margin: 0 1rem;
          font-size: 12px;
          border-radius: 2rem;
          height: 3.5rem;
        }
      }
      &-question {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_btn {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
        }
      }
    }
  }

  @include tablet-max {
    width: 100%;
  }

  &__wrapper {
    background: $white;
    padding-top: 4rem;
    box-shadow: 0 0 2rem 0 rgba(40, 58, 151, 0.2);
    border-radius: 2rem;

    @include tablet-max {
      padding-top: 2rem;
    }
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @include tablet-max {
      position: static;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $white;
      border-radius: 1rem;
      padding: 1rem;
      margin-bottom: 1rem;
    }
  }

  &__status-wrap {
    position: absolute;
    top: 2rem;
    left: 2rem;

    @include tablet-max {
      position: static;
    }
  }

  &__status {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.9rem;
    letter-spacing: 0em;
    padding: 0.5rem 1rem 0.5rem 2.2rem;
    color: $green;
    background-color: $light-green;
    border-radius: 0.6rem;
    position: relative;

    &::before {
      content: ".";
      position: absolute;
      left: 0.8rem;
      top: 0.4rem;
      font-size: 3.5rem;
      line-height: 0;
      color: $green;
      background-color: $light-green;
    }

    &--offline {
      color: $red;
      background-color: $light-red;

      &::before {
        color: $red;
        background-color: $light-red;
      }
    }
  }

  &__info-wrap {
    display: flex;
  }

  &__img-wrap {
    display: flex;
    border: 0.4rem solid $green;
    border-radius: 50%;
    padding: 0.6rem;
    align-items: center;

    &__offline {
      border-color: $red;
    }
  }

  &__img {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
  }

  &__container {
    @include flex($flex-direction: column, $align-items: center);
  }

  &__wrap {
    @include flex($flex-direction: column, $align-items: center);

    width: 100%;
    padding: 0 4rem;

    @include tablet-max {
      padding: 0 2rem;
    }

    &-box {
      margin-bottom: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mobile-small {
        flex-direction: column;
      }
    }
  }

  &__info {
    margin-left: 1.6rem;
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.6rem;
    margin-right: 0.8rem;
    text-transform: uppercase;
    color: $dark-blue;
    margin-bottom: 0.4rem;

    @include tablet-max {
      margin-bottom: 0.2rem;
      padding: 0;
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  &__sub-title {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 0.8rem;
    color: var(--black);

    @include tablet-max {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.9rem;
      margin-bottom: 1rem;
    }
  }

  &__params {
    @include flex($align-items: center, $justify-content: center);

    &-item {
      @include flex();

      font-size: 1.4rem;
      opacity: 0.8;
      font-weight: 400;
      letter-spacing: 0;
      color: $gray;

      &:first-child {
        &::after {
          @include flex($align-items: center, $justify-content: center);

          content: ".";
          width: 1rem;
          height: 0;
          margin: 0 0.8rem;
          font-size: 3rem;
        }
      }

      @include tablet-max {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
      }
    }
  }

  &__banner {
    @include flex(
      $flex-direction: column,
      $align-items: center,
      $justify-content: center
    );
    width: 100%;
    border-radius: 0.8rem;
    padding: 2rem;
    background-color: $lightest-gray;

    @include tablet-max {
      margin-bottom: 2.5rem;
    }

    &--info {
      background-color: $extra-light-orange;
    }

    &-title {
      font-size: 1.6rem;
      font-weight: 700;
      opacity: 0.8;
      text-align: center;

      @include tablet-max {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.9rem;
      }

      &--info {
        color: $orange;
      }

      &--with-desc {
        margin-bottom: 1.2rem;
      }
    }

    &-description {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6rem;
      max-width: 46rem;
      color: $gray;
      opacity: 0.8;

      text-align: center;
    }

    &--empty {
      margin-bottom: 4rem;
    }
  }

  &__assessment-name {
    margin-bottom: 1rem;
    padding: 0 0.5rem;
    font-style: normal;
    font-weight: 600;
    font-size: 2.3rem;
    line-height: 3.1rem;
    color: $black;
    opacity: 0.8;
    text-align: center;
    word-break: break-word;

    @include tablet-max {
      margin-bottom: 0.5rem;
      padding: 0;
      font-size: 1.4rem;
      line-height: 1.9rem;
      text-align: start;
    }
  }

  &__status-subtitle {
    margin-bottom: 0.2rem;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    color: $gray;

    @include tablet-max {
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }

  &__shared {
    margin-bottom: 3rem;
    width: 100%;
    padding: 1.5rem;
    background-color: $lightest-gray;
    border-radius: 0.6rem;

    @include tablet-max {
      margin-bottom: 2.5rem;
      max-width: 100%;
    }

    &-item {
      @include flex($align-items: center, $justify-content: space-between);

      overflow: hidden;
      position: relative;

      &--disabled {
        opacity: 0.5;
      }

      &-wrap {
        &--long {
          width: calc(100% - 5.5rem);
        }
      }

      &:not(:last-child) {
        margin-bottom: 1.3rem;
      }

      &-title {
        margin-bottom: 0.3rem;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $gray;
      }

      &-description {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.9rem;
        white-space: nowrap;
        color: $dark-blue;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: lowercase;
        cursor: pointer;
      }

      &-btn {
        min-width: 3.8rem;
        height: 3.8rem;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 1.9rem;

        &--with-padding {
          padding: 0 2rem;
        }
      }
    }

    &-list {
      display: grid;
      grid-row-gap: 3rem;
      grid-column-gap: 2rem;
      padding: 0.8rem;
      grid-template-columns: repeat(4, 18rem);

      @include tablet-max {
        grid-template-columns: repeat(2, 50%);
      }
    }

    &-user {
      font-size: 1.2rem;
      font-weight: 400;
      color: $white;
      line-height: 1.7rem;
      text-transform: capitalize;

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }

      &--received {
        color: $green;
      }
    }
  }

  &__controls {
    width: 100%;
    margin-top: 2.4rem;

    @include tablet-max {
      margin: 0 0 1rem 0;
      max-width: 100%;
    }

    &-btn {
      padding: 1.2rem 2.4rem;
      height: initial;

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &--mobile-short {
        @include tablet-max {
          width: calc(50% - 0.5rem);
          margin-bottom: 1rem;
          flex-grow: 0;

          &:not(:last-child) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__controls-panel {
    @include flex($align-items: center, $justify-content: center);

    &--mobile-wrap {
      @include tablet-max {
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    .base-group {
      @include tablet-max {
        margin-right: 0;
      }
    }
  }

  &__gear {
    @include backgroundImage();

    width: 3.2rem;
    height: 3.2rem;
    cursor: pointer;
    background-size: contain;
    background-image: url("../../../shared/images/icons/icon-gear-blue.svg");

    @include tablet-max() {
      width: 2.5rem;
      height: 2.5rem;
      margin-left: 0.4rem;
    }
  }

  &__players-connected {
    @include flex($align-items: center, $justify-content: center);

    margin: 3.2rem 0;
    width: 100%;
    border-bottom: 0.1rem solid $color-25;

    @include tablet-max {
      margin-bottom: 4.2rem;
    }

    &-text {
      padding: 0 1.6rem 0 3.6rem;
      margin-bottom: -1.2rem;
      font-size: 1.6rem;
      line-height: 2.2rem;
      line-height: 2.2rem;
      color: $gray;
      background: $white;
      font-weight: normal;
      position: relative;
      cursor: pointer;

      @include tablet-max {
        padding: 0 1.6rem 0 3.8rem;
        font-size: 1.4rem;
        line-height: 1.9rem;
      }

      &::before {
        @include backgroundImage();

        content: "";
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        left: 0.9rem;
        top: 0;
        font-size: 3.5rem;
        line-height: 0;
        background-image: url("../../../shared/images/icons/icon-profile-gray.svg");

        @include tablet-max {
          top: 0.1rem;
          left: 1.5rem;
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }

  &__board {
    padding: 0.4rem 0.2rem;

    &-title {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.9rem;
      color: $white;
      margin-bottom: 1.2rem;
    }
    &-grid {
      display: grid;
      grid-template-columns: 16rem;
      grid-row-gap: 1rem;
      grid-column-gap: 4rem;
    }
    &-block {
      width: 16rem;
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      color: $white;
    }
    &-label {
      color: $extra-light-gray;
    }
  }

  &__table {
    height: 42.5rem;
    width: 100%;
  }

  &-roles {
    max-width: 65rem;
    width: 100%;
    position: relative;

    @include tablet-max {
      max-width: 100%;
    }

    &__description {
      margin-bottom: 2.5rem;
    }

    &__text {
      font-size: 1.1rem;
      margin-bottom: 1rem;

      &--italic {
        font-style: italic;
      }

      &--bold {
        font-weight: bold;
      }

      &--center {
        text-align: center;
      }
    }

    &__list {
      padding-left: 1.5rem;
      margin-bottom: 2rem;
    }

    &__list-item {
      font-size: 1.1rem;
      margin-bottom: 0.6rem;
      font-style: italic;
    }

    &__title {
      text-align: center;
      font-size: 1.6rem;
      font-weight: bold;
      margin-top: 3rem;
    }

    &__items {
      @include flex($flex-wrap: wrap, $justify-content: space-between);

      margin-top: 3rem;
    }

    &__item {
      max-width: 30rem;
      width: 100%;

      &:not(:nth-last-child(-n + 2)) {
        margin-bottom: 4rem;
      }

      @include tablet-max {
        max-width: 100%;

        &:not(:last-child) {
          margin-bottom: 4rem;
        }
      }

      &-title {
        margin-bottom: 0.7rem;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: $color-26;
      }

      &-description {
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $gray;
      }
    }
  }

  &-tooltip {
    &__list {
      width: 21.4rem;

      &-item {
        display: none;

        &:last-child {
          margin-bottom: 0;
        }

        &--show {
          @include flex(
            $align-items: center,
            $justify-content: space-between,
            $flex-wrap: nowrap
          );
          margin-bottom: 0.8rem;
        }
      }

      &-text {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
        color: $white;

        &--light-gray {
          color: $extra-light-gray;
        }
      }
    }
  }
}
