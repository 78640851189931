@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/variables/variables";

.gt-popup {
  width: 44rem;
  text-align: center;

  @include mobile {
    width: 100%;
  }

  &__header {
    width: 100%;
    overflow: hidden;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 100% / (440/183);
      background-image: url("../../../images/gt-header.png");
      background-position: center;
      background-size: cover;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  &__title {
    font-weight: normal;
    margin-bottom: 2rem;
    color: $black;
  }

  &__content {
    font-size: 1.4rem;
    padding: 4rem;
    color: $dark-gray;
    line-height: (22/14);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    @include mobile {
      padding: 1.5rem;
    }
  }

  &__button {
    &--download {
      min-width: 16.2rem;
    }

    &--understand {
      margin-top: 3rem;
      margin-bottom: 1.4rem;
    }
  }

  &__link {
    color: $dark-blue;
    font-weight: 700;
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  &__text {
    max-width: 36rem;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:not(:first-child) {
      margin-top: 1rem;
    }

    &--left {
      text-align: left;
    }

    @at-root ol#{&} {
      margin: 0;
      padding: 0;
      list-style-position: inside;
    }
  }

  &__video {
    width: 100%;
    display: block;
  }

  &__video-wrapper {
    border-radius: $border-radius-small;
    overflow: hidden;
    border: 0.1rem solid $extra-light-gray;
    width: 100%;

    &:not(:first-child) {
      margin-top: 2rem;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__menu-icon {
    background-image: url("../../../images/icons/3-vertical-dots.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    margin-top: -0.1em;
    display: inline-block;
    vertical-align: middle;
  }
}
