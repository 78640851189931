@import "src/app/styles/mixins/grid";

.rights {
  @include flex(flex-start, flex-start, nowrap, column);
  gap: 1.8rem;
}

.rightsWrapper {
  @include flex(flex-start, flex-start, nowrap, column);
  gap: 1.8rem;
}

.right {
  @include flex(flex-start, flex-start);
  gap: 1rem;

  &_radio {
    gap: 0;
  }
}

.subrights {
  padding-left: 2rem;
}

.rightName {
  font-size: 1.4rem;
  font-weight: 600;
  cursor: pointer;
  color: $black;

  &.disabled {
    cursor: auto;
  }
}
