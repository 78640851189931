@import "src/app/styles/mixins/media-queries";

.assessment-results-popup {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 131rem;
  transition: width 0.5s ease;

  &--solo {
    width: 131rem;
  }

  &--team {
    width: 157rem;

    @include mobile {
      width: 153rem;
    }
  }

  &--corp {
    width: 162rem;

    @include mobile {
      width: 153rem;
    }
  }

  &--buttons-export {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    column-gap: 2rem;
  }

  @include tablet-max {
    max-width: 100%;
  }
}
