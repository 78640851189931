@import "src/app/styles/mixins/grid";

.rights {
  padding: 0;

  &__button {
    width: 100%;
    padding: 1.7rem 2.4rem;
    @include flex(center, center);
  }
}
