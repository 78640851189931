@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";

.basePanel {
  padding: 0 2rem;
  max-width: 119rem;
  width: 100%;
  background: $white;
  border-radius: $border-radius-large;
  box-shadow: 0 0 2rem 0 rgba($dark-blue, 13%);
  margin-bottom: 3rem;

  @include tablet-max {
    padding: 0 2rem;
  }

  overflow-x: auto;
}

.children {
  @include flex(center, center);

  width: 100%;
  height: 7.3rem;

  @include tablet-max {
    height: 5.7rem;
    transition: height 0.3s, opacity 0.2s 0.1s;
  }

  &.headerHidden {
    @include tablet-max {
      transition: height 0.3s, opacity 0.2s;
      opacity: 0;
      height: 0;
    }
  }
}
