@import "src/app/styles/mixins/grid.scss";

.contact-position {
  @include flex($align-items: center, $justify-content: space-between);

  &__description {
    margin-bottom: 1rem;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $gray;
  }

  &__value {
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__lang {
    min-width: 30%;
    width: 30%;
    margin-right: 1rem;
  }
}
