.user_rights {
  & > div > div {
    width: 42rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__wrapper {
    margin-bottom: 3.5rem;
  }
}
