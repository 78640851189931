@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/font";
@import "src/app/styles/mixins/utils";

.base-tooltip {
  position: relative;
  z-index: var(--z-index-base-tooltip);
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0.8rem;
  padding: 1.2rem;
  background-color: $tooltips-bg-no-blur;
  color: $white;
  font-weight: initial;
  font-size: 1.2rem;
  line-height: (16.34/12);

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(4px);
    background-color: $tooltips-bg;
  }

  &--mobile-wide {
    @include tablet-max {
      width: calc(100% - 3rem);
    }
  }
}

.tooltip-wrapper {
  &:hover .base-tooltip {
    opacity: 1;
    visibility: visible;
  }

  &--show {
    .base-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
}
