.wrapper {
  padding: 4rem;

  div {
    margin-bottom: 2rem;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.rowPagination {
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
}