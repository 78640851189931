@import "src/app/styles/mixins/grid";

.reset-password {
  padding: 0;

  &__button {
    width: 100%;
    padding: 1.2rem 2.4rem;

    @include flex(center, center);
  }
}
