@import "../../../../app/styles/variables/variables.scss";
@import "../../../../app/styles/mixins/grid.scss";

.map_wrapper {
  height: 100%;
  @include flex(center, flex-end);
  margin: -10px;

  > * {
    max-height: 100%;
    width: 100%;
  }
}

.zoom_controller {
  background: $neutral-n-0;
  border: 4px solid $neutral-n-50;
  box-shadow: 0px 0px 20px 0px rgba(40, 58, 151, 0.2);
  border-radius: 8px;
  @include flex($flex-direction: column);
  position: absolute;
  width: max-content;
  right: 40px;

  button {
    height: 56px;
    width: 56px;
    @include flex(center, center);
  }
}

.map {
  border-radius: 10px;
}

.marker {
  pointer-events: none;
}

.city_label {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  fill: $neutral-n-300;
  text-shadow:
    -0.5px -0.5px 0 $neutral-n-0,
    0.5px -0.5px 0 $neutral-n-0,
    -0.5px 0.5px 0 $neutral-n-0,
    0.5px 0.5px 0 $neutral-n-0;
}

.tooltip {
  padding: 12px;
  border-radius: 8px !important;
  backdrop-filter: blur(4px);
  font-size: 12px;
  color: $neutral-n-50 !important;
  max-width: max-content;

  h5 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: center;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  li {
    display: flex;
    justify-content: space-between;
    gap: 32px;

    span:last-child {
      color: $neutral-n-0;
    }
  }

  p {
    text-align: center;
  }
}

.geography {
  outline: none;
}
