@import "src/app/styles/variables/variables";

.base-popover-wrapper {
  position: relative;
}

.base-popover {
  display: flex;
  position: absolute;
  left: initial;
  right: 0;
  margin-top: 3rem;
  top: 100%;
  z-index: 5;
  padding: 2rem;
  flex-direction: column;
  border-radius: 12px;
  animation: fade-out-modal 0.2s ease-in-out;
  background: $popover-bg;

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(4px);
  }

  &__is_closed {
    animation: fade-in-modal 0.2s ease-in-out;
  }

  &__title {
    display: flex;
    font-size: 1.4rem;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    justify-content: flex-start;
    margin-bottom: 0.4rem;
  }
}
