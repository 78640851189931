.base-select-focused {
  .base-select__input-container {
    position: absolute;
    top: auto !important;
  }

  .base-select__single-value {
    display: none;
  }
}

.base-select-hidden {
  display: none;
}
