.allocate-popup {
  &__title {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: 600;
    justify-content: space-between;

    &-player-info {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }


    &-avatar {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;

      & > img {
        width: 80px;
        height: 80px;
        margin-right: 2rem;
      }
    }

    &-desired-role {
      margin-left: 0.4rem;
      color: #63677c;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__content {
    display: grid;
    grid-row-gap: 20px;
    padding-bottom: 30px;
    align-items: center;
    width: 500px;
  }
  @media (max-width: 767px) {
    &__content {
      width: auto;
    }
  }
  .base-select {
    &__menu {
      width: 500px;
    }
    @media (max-width: 767px) {
      &__menu {
        position: relative;
        width: 100%;
      }
    }
  }
}
