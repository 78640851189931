@import "src/app/styles/mixins/media-queries";

.information-popup {
  max-width: 54rem;
  width: 100%;

  @include mobile {
    max-width: 100%;
  }

  &__inner-html {
    padding: 0;
    overflow: initial;
  }
}
