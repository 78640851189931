@import "src/app/styles/mixins/utils";

.grid-accordion {
  border-radius: var(--border-radius);
  overflow: hidden;

  &__header {
    padding-right: 3.5rem;
    height: 5.6rem;
    background: var(--extra-light-purple);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &::after {
      @include backgroundImage($background-size: contain);

      min-width: 1.3rem;
      width: 1.3rem;
      height: 1.3rem;
      content: "";
      background-image: url("../../../images/icons/arrow-down-dark-gray.svg");
      transition: 0.3s;
    }

    &--active {
      &::after {
        transform: rotate(180deg);
      }
    }

    &-slot {
      flex: 1;
    }
  }
}
