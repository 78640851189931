.wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 473px 674px;
  min-height: 100dvh;
  margin: 40px auto 0;
  max-width: 1188px;
  width: 100%;
  gap: 24px;
}
