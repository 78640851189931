@import "../../../app/styles/variables/variables.scss";
@import "../../../app/styles/mixins/grid.scss";

.block {
  background: $neutral-n-0;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0px 0px 20px 0px rgba(40, 58, 151, 0.2);
  grid-column: 1 / 3;
  position: relative;

  header {
    @include flex(center);
    gap: 16px;
    position: absolute;
    width: 100%;
    max-width: 356px;
    right: 40px;
  }
}

.switcher {
  width: 100%;
  max-width: 316px;
}

.tooltip {
  color: $neutral-n-0;

  &_wrapper {
    color: $neutral-n-100;
    transition: 0.15s;
    cursor: pointer;

    &:hover {
      color: $blue-b-100;
    }
  }

  h5 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 4px;
  }

  p {
    @include flex(center, space-between);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;

    span:first-child {
      color: $neutral-n-50;
    }

    span:last-child {
      color: $neutral-n-0;
    }
  }
}
