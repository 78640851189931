@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils.scss";

.page-404 {
  @include flex($flex-direction: column, $justify-content: center);

  padding: 2rem 4rem 2rem 13rem;
  min-height: 100vh;
  position: relative;

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      @include tablet-max {
        object-position: top;
      }
    }
  }

  @include tablet-max {
    min-height: calc(100vh - 6rem);
    padding: 8.5rem 2rem 8.5rem 4rem;
    justify-content: flex-end;
  }

  &__title {
    font-weight: 800;
    font-size: 20rem;
    line-height: 20rem;
    z-index: 1;
    color: $white;

    @include tablet-max {
      font-size: 12rem;
      line-height: 12rem;
    }
  }

  &__subtitle {
    margin: 2rem 0;
    font-weight: 800;
    font-size: 4rem;
    line-height: 5.4rem;
    z-index: 1;
    color: $white;

    @include tablet-max {
      margin: 1.5rem 0 2rem;
      font-size: 2rem;
      line-height: 2.7rem;
    }
  }

  &__description {
    font-weight: normal;
    font-size: 3rem;
    line-height: 4.4rem;
    z-index: 1;
    color: $lightest-gray;

    @include tablet-max {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}
