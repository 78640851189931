@import "variables/variables";
@import "helpers/helpers";
//@import "./modules/font";
//@import "./modules/input";
//@import "./modules/select";
//@import "./modules/checkbox";
//@import "./modules/popup";
//@import "./modules/table";
//@import "./modules/chat";
//@import "./modules/animations";

@import "variables/global";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  scrollbar-width: thin;
  scrollbar-color: $scroll-slider rgba($extra-light-gray, 0.5);
}

*::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

*::-webkit-scrollbar-track {
  background: rgba($extra-light-gray, 0.5);
  border-radius: 0.2rem;
}

*::-webkit-scrollbar-thumb {
  background: $scroll-slider;
  border-radius: 0.2rem;
}

*::-webkit-scrollbar-thumb:hover {
  background: darken($scroll-slider, 10%);
}

html {
  font-size: 10px;
}

body {
  &::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
  }
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.App {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

select[disabled] {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  color: black;
  border: none;
  font-size: 18px;
}

.app-preloader {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ql-tooltip.ql-editing {
  left: 0 !important;
}

.quill-error .ql-toolbar,
.quill-error .ql-container {
  border-color: $red !important;
}

.user-information .ql-editor,
.user-information .ql-editor ul {
  padding: 0;
}

// TODO: Изменить стили для автоматического соотношения сторон
.ql-editor iframe {
  width: 100%;
  height: 32rem;

  @include tablet-max {
    height: 17rem;
  }
}

.required::after {
  content: "*";
  display: inline;
}

.react-datepicker {
  font-size: 1.2em !important;
}

.react-datepicker__month {
  margin: 0.4em 1em !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
}
.react-datepicker__current-month {
  font-size: 1em !important;
}
