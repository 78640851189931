@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/font.scss";

.ui-kit {
  padding-bottom: 15rem;

  &__title {
    @include set-font(null, 1.8rem, 1.9rem, 600);

    margin-bottom: 2rem;
  }
  &__block {
    margin-bottom: 2rem;

    & > * {
      margin-bottom: 2rem;
    }

    &--darken {
      background-color: #6672ad4a;
    }
  }
  &__text {
    @include set-font(null, 1.4rem, 1.9rem, 500);

    margin-bottom: 2rem;
  }
}
