@import "../../../app/styles/mixins/grid";

@mixin colors() {
  &--communication {
    background: $blue;
  }

  &--analytics {
    background: $purple;
  }

  &--command {
    background: $sea-wave;
  }

  &--thinking3d {
    background: $pale-orange;
  }

  &--economics {
    background: $pink;
  }

  &--jurisprudence {
    background: $bright-green;
  }
}

.lvl-state {
  @include flex($align-items: center, $justify-content: space-between);

  position: relative;
  &--is-dropdown {
    @include flex($align-items: center);
  }

  &__title {
    @include flex($align-items: center, $justify-content: center);
    @include colors();

    min-width: 2.4rem;
    width: 2.4rem;
    height: 2.4rem;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $white;
    border-radius: 100%;

    @include tablet-max {
      margin-right: 1rem;

      &_is_dropdown {
        margin-right: 0;
      }
    }
  }

  &__line {
    margin: 0 1.4rem;
    flex: 1;
    height: 0.8rem;
    background: $scale-bg-in-menu;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;

    @include tablet-max {
      display: none;
    }

    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;

      @include colors();
    }
  }

  &__rate {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: $white;
    &_is_dropdown {
      margin-left: 0.4rem;
    }
  }

  &__diff {
    border: 0.4rem solid transparent;
    position: absolute;
    right: -1.6rem;

    @include tablet-max {
      display: none;
    }

    &--increase {
      margin-bottom: 0.3rem;
      border-bottom: 0.4rem solid $green;
    }

    &--decrease {
      margin-top: 0.3rem;
      border-top: 0.4rem solid $red;
    }
  }
}
