@import "src/app/styles/variables/variables.scss";
@import "src/app/styles/mixins/utils";
@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";

@mixin color($color) {
  &:before,
  &:after {
    background-color: $color;
  }
}

.icon-cross {
  position: relative;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    left: auto;
    right: auto;
    bottom: 0;
    content: "";
    height: 100%;
    width: 0.2rem;
    background-color: $gray;
  }
  &:before {
    transform: rotate(0.125turn);
  }
  &:after {
    transform: rotate(-0.125turn);
  }

  &--blue {
    @include color($dark-blue);
  }

  &--red {
    @include color($red);
  }

  &--dark-gray {
    @include color($dark-gray);
  }
}
