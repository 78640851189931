@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/mixins/utils";

.join_code {
  background: $neutral-n-0;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0px 0px 20px 0px rgba(40, 58, 151, 0.2);
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 1023px) {
    padding: 24px;
  }

  h4 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
    color: $neutral-n-300;

    @media (max-width: 1023px) {
      font-size: 20px;
    }
  }
}
