@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils";

.stat-block {
  width: 43rem;

  &--not-is-player {
    width: auto;
  }

  @include tablet-max {
    width: 100%;
  }

  &__container {
    @include flex($align-items: stretch);

    margin-bottom: 2rem;
  }

  &__img {
    @include backgroundImage();

    margin-right: 3rem;
    min-width: 17rem;
    width: 17rem;
    height: 17rem;

    @include tablet-max {
      margin-right: 2rem;
      min-width: 11.5rem;
      width: 11.5rem;
      height: 11.5rem;
    }
  }

  &__list {
    @include flex($flex-direction: column, $justify-content: space-between);

    &-item {
      @include flex($align-items: center);

      font-size: 1.6rem;
      line-height: 2.2rem;

      @include tablet-max {
        font-size: 1.3rem;
        line-height: 1.6rem;
      }

      &-title {
        margin-right: 2rem;
        width: 13.5rem;
        font-weight: normal;
        color: $gray;

        @include tablet-max {
          margin-right: 0.5rem;
          width: 11.5rem;
        }
      }

      &-description {
        font-weight: 600;
        color: $black;
      }
    }
  }
}
