@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils.scss";

.email-newsletter-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  overflow: inherit;
  flex: 1;

  &__info-page {
    @include flex($flex-direction: column);

    //noinspection CssOverwrittenProperties
    @include mobile {
      padding-bottom: (5.6rem + 2rem + 0.5rem);
      padding-bottom: calc(
              env(safe-area-inset-bottom) + #{5.6rem + 2rem + 0.5rem}
      );
    }

    &-data {
      @include flex($flex-direction: column);

      height: 65rem;
      align-items: normal;
      max-width: 98rem;

      @include mobile {
        margin-bottom: 1rem;
      }
    }

    &-form {
      display: grid;
      gap: 2rem;

      @include mobile {
        width: 100%;
        display: flex;
        gap: initial;
        flex-direction: column;
        align-items: stretch;
      }
    }
  }

  &__text-input {
    min-height: 48rem;
    height: 48rem;
  }
}
