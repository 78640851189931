@import "src/app/styles/variables/variables";
@import "src/app/styles/mixins/media-queries";

.session-event-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999;

  width: 6rem;
  height: 6rem;
  background: $red;
  box-shadow: 0 0 2rem rgba(255, 61, 61, 0.6);
  border-radius: 50%;

  @include tablet() {
    display: none;
  }
}
