@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/media-queries";
@import "src/app/styles/mixins/utils";
@import "src/app/styles/variables/variables";

.form {
  width: 100%;
}

.supportDescription {
  margin-top: 0.8rem;
  color: $dark-gray;
  font-size: 1.4rem;

  &__link {
    color: $dark-blue;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.baseGroup {
  margin-bottom: 2rem;

  .base-group__item {
    height: 100%;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  column-gap: 1.6rem;
  margin-bottom: 2.4rem;

  @include laptop {
    margin-right: 1.6rem;
    min-width: 39rem;
  }

  @include tablet-max {
    @include flex(unset, flex-start, nowrap, column);
  }

  & > div {
    max-width: 50rem;
  }
}

.fieldsetTitle {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.genderSelect {
  display: flex;
  gap: 0.5rem;
  background: $extra-light-gray;
  padding: 0.4rem;
  border-radius: $border-radius-small;
  height: 100%;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__item {
    flex: 1;
    @include flex(center, center);

    border-radius: $border-radius-smallest;
    background: transparent;
    font-size: 2rem;
    font-weight: 700;
    color: $black;
    padding: 1rem;
    cursor: pointer;
    user-select: none;

    transition: $transition;

    &:hover {
      background: lighten($extra-light-gray, 5%);
    }

    &Selected {
      background: white;
      box-shadow: 0 0 2rem 0 #283a9733;
    }
  }
}

.checkbox {
  gap: 1rem;
  margin-top: 1.7rem;

  span {
    font-size: 1.4rem;
    font-weight: 600;
  }
}
