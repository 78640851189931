@import "src/app/styles/mixins/media-queries";

.tutorial-history-data-grid-wrapper {
  height: calc(100vh - 16.3rem);

  @include mobile-landscape {
    height: initial;
  }

  @include laptop {
    height: calc(100vh - 16.3rem);
  }
}
