@import "src/app/styles/mixins/media-queries.scss";

.sms-mailing-lists-page {

  height: calc(100vh - 26.1rem);

  @include mobile-landscape {
    height: initial;
  }

  @include tablet-landscape {
    height: initial;
  }

  @include laptop {
    height: calc(100vh - 29rem);
  }
}