@import "../../../../../app/styles/variables/variables.scss";

.roles_tooltip {
  font-size: 12px;
  color: $neutral-n-50 !important;
  width: 160px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.role_players_count {
  color: $neutral-n-0;
  margin-left: auto;
}

.role_players_max {
  color: inherit;
}
