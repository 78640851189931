@import "src/app/styles/mixins/media-queries";

.game-type-availability {
  &__title {
    margin-bottom: 0.6rem;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: var(--gray);

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  &__list {
    display: flex;
    justify-content: flex-end;
    list-style: none;

    &-item {
      padding: 0.4rem 0.5rem;
      border-radius: var(--border-radius-smallest);
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.4rem;
      background: var(--light-red);
      color: var(--red);

      &:not(:first-child) {
        margin-left: 0.4rem;
      }

      &--available {
        background: var(--light-green);
        color: var(--green);
      }
    }
  }
}
