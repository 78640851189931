@import "src/app/styles/mixins/grid.scss";

.assessment-avatar {
  width: 100%;
  position: relative;
  display: block;
  margin-bottom: 1.5rem;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    font-size: 0;
    border: 0.4rem solid darken($extra-light-gray, 5%);
    box-shadow: 0 0 3rem rgba($extra-light-gray, 0.4);
    background-color: $extra-light-gray;
    border-radius: (30/160) * 100%;

    @include tablet {
      border-radius: 3rem;
      border-width: 0.8rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--is-add {
      background-color: $lightest-gray;
      border: 0;
      box-shadow: none;
      cursor: pointer;
      outline: none;

      &:focus {
        box-shadow: 0 0 0 0.4rem lighten($button-primary-bg, 50%);
      }

      &:focus:not(:focus-visible) {
        box-shadow: none;
      }

      &:focus-visible {
        box-shadow: 0 0 0 0.4rem lighten($button-primary-bg, 50%);
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 100% * (10/160);
        height: 100% * (64/160);
        background-color: $extra-light-gray;
        border-radius: 0.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(0.25turn);
      }
    }
  }

  &__users {
    @include flex($align-items: center);

    appearance: none;
    position: absolute;
    bottom: 0;
    border: 0;
    left: 50%;
    background-color: darken($extra-light-gray, 5%);
    transform: translate(-50%, 50%);
    box-shadow: 0 0 3rem rgba($extra-light-gray, 0.4);
    border-radius: 2rem;
    text-align: center;
    cursor: pointer;
    line-height: 1;
    font-size: 1.4rem;
    padding: 0.6rem 1.2rem;
    white-space: nowrap;

    &:disabled {
      cursor: not-allowed;
    }

    &-icon {
      margin-right: 0.4rem;
      transform: scale(0.85);

      @include tablet {
        margin-right: 1rem;
        transform: scale(1);
      }
    }
  }
}
