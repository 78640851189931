@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/grid.scss";
.my-sessions-page {
  &__nav {
    @include flex($justify-content: center);

    margin: 0 auto 3rem;
  }
}

.created-assessments-controls {
  @include flex;
  gap: 1.2rem;
}

.copy-assessment-popup {
  .base-popup__content {
    max-width: 44rem;
  }

  &__content {
    @include flex(unset, flex-start, nowrap, column);
    gap: 2rem;
    margin-top: 2rem;
  }
}
