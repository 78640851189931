@import "src/app/styles/mixins/media-queries";

.table-settings-popup {
  width: 35rem;

  @include tablet-max {
    width: 100%;
  }

  &__description {
    margin-bottom: 3rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: center;
    color: var(--dark-gray);
  }

  &__btn {
    margin-top: 3rem;
  }
}
