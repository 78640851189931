.hover {
  position: relative;

  &__visible:hover + &__onhover {
    display: block;
  }

  &__onhover {
    position: absolute;
    left: 0;
    display: none;
  }
}
