.search-input {
  width: 100%;
  position: relative;

  &__field {
    width: 100%;
    padding: 1rem 4.1rem;
    background: var(--lightest-gray);
    border-radius: var(--border-radius-small);
    border: 0.1rem solid var(--lightest-gray);
    outline: 0;

    &:focus {
      background: transparent;
      border-color: var(--extra-light-gray);
    }
  }

  &__magnifier,
  &__cross {
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__magnifier {
    left: 1.2rem;
    color: var(--gray);
  }

  &__cross {
    right: 1.2rem;
    display: none;
    color: var(--light-purple);
  }

  &__field:focus ~ &__magnifier {
    color: var(--dark-blue);
  }

  &__field:focus ~ &__cross,
  &__cross--visible {
    display: flex;
  }

  &__field:focus ~ &__cross {
    color: var(--extra-light-gray);
  }
}
