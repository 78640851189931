@import "src/app/styles/mixins/utils";
@import "src/app/styles/variables/variables";

.resume-download-block {
  width: 100%;
  display: flex;
  gap: 0.8rem;
  margin-bottom: 2rem;

  &__btn,
  &__delete,
  &__link {
    display: flex;
    font-size: 1.4rem;
  }

  &__btn {
    width: 100%;
    padding: 1.2rem 2.4rem;
  }

  &__content {
    display: flex;
    align-items: center;

    &::before {
      @include backgroundImage($background-size: contain);

      margin-right: 1rem;
      min-width: 1.6rem;
      width: 1.6rem;
      height: 1.6rem;
      content: "";
      background-image: url("../../images/icons/upload-blue.svg");
    }

    &-label {
      min-width: 0;
      overflow: hidden;
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1.4rem;
      font-weight: bold;
      color: $dark-blue;
      width: 21rem;
    }
  }

  &__delete {
    cursor: pointer;
    border-radius: var(--border-radius-small);
    transition: var(--transition);
    padding: 1.2rem;

    .text-with-icon__icon {
      height: 1.8rem !important;
      width: 1.8rem !important;
    }

    &:disabled {
      cursor: not-allowed;
      filter: grayscale(1);
    }
  }
}
