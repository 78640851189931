@import "src/app/styles/mixins/grid.scss";

.tab-heading {
  @include flex(
    $justify-content: space-between,
    $flex-wrap: wrap,
    $align-items: center
  );
  width: 100%;
  min-height: 4rem;

  &__title {
    font-weight: 800;
    font-size: $font-size-6;
    color: $black;
  }

  &__button-list {
    @include flex($flex-wrap: wrap);
  }
}
