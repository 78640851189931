@import "src/app/styles/mixins/grid.scss";

.participants {
  @include flex($flex-direction: column);

  &__button {
    font-size: $font-size-3;
  }

  &__button-icon {
    margin-right: 1rem;
  }

  &__list {
    @include flex($flex-direction: column);
    row-gap: 1rem;
    margin-top: 3rem;
    list-style: none;
    width: 100%;
  }
}
