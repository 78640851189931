@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/utils";
@import "src/app/styles/mixins/media-queries";

.contacts-info-block {
  padding: 3rem;

  @include tablet-max {
    padding: 1.5rem;
  }

  &__title {
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.7rem;
    color: var(--black);
  }

  &__description {
    margin-bottom: 3.2rem;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: $black;

    @include tablet-max {
      margin-bottom: 1.6rem;
      font-size: 1.4rem;
      line-height: 2.5rem;
    }

    &-link {
      color: $dark-blue;
      text-decoration: none;
      font-weight: bold;
    }
  }

  &__chats {
    @include flex($align-items: center);

    &-control {
      padding: 1.25rem;
      background: $lightest-gray;
      text-decoration: none;
      border-radius: $border-radius-small;

      @include tablet-max {
        width: 100%;
      }

      &--telegram-white,
      &--whats-app-white {
        padding: 1.25rem 1.6rem;
      }

      &--telegram-white {
        margin-right: 1rem;
        background: $blue;
      }

      &--whats-app-white {
        background: $green;
      }
    }
  }
}
