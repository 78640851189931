@import "src/app/styles/mixins/utils";

@import "src/app/styles/mixins/media-queries";

.game-type-mode {
  &__title {
    margin-bottom: 0.6rem;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: var(--gray);

    @include mobile {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }

  &__description {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: var(--dark-blue);
    display: flex;
    align-items: center;

    .base-info {
      height: 25px;
    }

    grid-gap: 0.6rem;

    @include mobile {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }

    &--completed {
      &::after {
        @include backgroundImage($background-size: contain);

        margin-left: 0.6rem;
        min-width: 1.8rem;
        width: 1.8rem;
        height: 1.8rem;
        content: "";
        background-image: url("../../../../../shared/images/icons/icon-check-green.svg");

        @include mobile {
          min-width: 1.6rem;
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }
  }
}
