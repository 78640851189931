@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";

.radio-btn {
  display: inline-block;

  &__input {
    display: none;

    &:checked + label:before {
      border: 0.6rem solid $dark-blue;
      @include tablet-max {
        border-width: 0.5rem;
      }
    }
    &_disabled:checked + label:before {
      border: 0.6rem solid #d4daec;
    }
  }

  &__label {
    @include flex($align-items: center);

    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #1a1a1a;
    cursor: pointer;

    @include tablet-max {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    &:before {
      margin-right: 1rem;
      content: "";
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 100%;
      border: 0.2rem solid $dark-blue;
      box-sizing: border-box;
      transition: border 0.15s linear 0s;

      @include tablet-max {
        margin-right: 0.5rem;
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    &:hover:before {
      border-color: lighten($dark-blue, 25%);
    }
  }

  &--disabled &__label {
    color: #8b8fa4;
    pointer-events: none;
    &:before {
      border: 0.2rem solid #d4daec;
    }
  }
}
