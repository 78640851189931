@import "src/app/styles/mixins/grid.scss";

.base-array-field {
  &__title {
    margin-bottom: 1rem;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $gray;
  }

  &__list {
    &--number-array {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 1.8rem 1rem;

      list-style: none;
    }

    &-item {
      &--string-array:not(:last-child) {
        margin-bottom: 1.4rem;
      }

      &-btn {
        margin-top: 0.6rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.4rem;
        color: $red;
        border: 0;
        background: transparent;
        cursor: pointer;
      }
    }

    &-add {
      margin-top: 2rem;

      &--number-array {
        @include flex($align-items: center, $justify-content: center);

        margin-top: 0;
        width: 100%;
        height: $field-height;
        border: 0.1rem dashed $extra-light-gray;
        border-radius: $border-radius-small;
        background: $white;
      }
    }
  }
}
