@import "src/app/styles/mixins/grid.scss";

.participant {
  @include flex($align-items: center);
  background-color: $white;
  width: 100%;
  box-shadow: 0 0 50px rgba(100, 163, 198, 0.1);
  border-radius: 10px;
  padding: 1rem 2rem;

  &_type_dark {
    background-color: $lightest-gray;
  }

  &__checkbox {
    margin-left: 2.5rem;
  }

  &__avatar {
    margin-left: 2rem;
  }

  &__hover {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    border-radius: 6px;
    color: $extra-light-gray;
    padding: 1rem 1.2rem;
    font-weight: 400;
    font-size: $font-size-1;
  }

  &__name {
    font-weight: 600;
    font-size: $font-size-3;
    color: $black;
    margin-left: 1rem;

    &_type_underline {
      text-decoration: underline;
    }
  }

  &__role {
    color: $dark-gray;
    font-weight: 400;
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 2rem;
  }

  &__status {
    margin-left: auto;
    font-size: $font-size-2;
    font-weight: 600;
    position: relative;

    &::before {
      content: "";
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
    }

    &_type_offline {
      color: $red;

      &::before {
        background-color: $red;
      }
    }

    &_type_online {
      color: $green;

      &::before {
        background-color: $green;
      }
    }
  }
}
