@import "src/app/styles/mixins/utils";

@import "src/app/styles/mixins/media-queries";

.controls-connector {
  padding: 1.2rem 0;
  width: 1.7rem;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile {
    padding: 1rem 0;
  }

  &::after,
  &::before {
    @include backgroundImage(
      $background-size: contain,
      $background-position: bottom
    );

    margin-left: 0.3rem;
    min-width: 0.7rem;
    width: 0.7rem;
    height: 1.1rem;
    content: "";
    display: block;
    background-image: url("../../../../../shared/images/icons/connector-edge-gray.svg");
  }

  &::after {
    background-position: top;
    transform: scaleY(-1);
  }

  &__item {
    @include backgroundImage($background-size: contain);

    min-width: 0.8rem;
    width: 0.8rem;
    height: 2rem;
    background-image: url("../../../../../shared/images/icons/connector-gray.svg");
    position: relative;
    display: flex;
    align-items: center;

    &::after,
    &::before {
      min-width: 0.2rem;
      width: 0.2rem;
      height: 2.8rem;
      border-radius: 0.4rem;
      content: "";
      display: none;
      background: var(--extra-light-gray);
      position: absolute;
      left: 0.3rem;

      @include mobile {
        height: 2.2rem;
      }
    }

    &--multi {
      &::after {
        display: block;
        top: 100%;
      }

      &:not(:last-child) {
        &::after {
          height: 2.5rem;

          @include mobile {
            height: 1.9rem;
          }
        }
      }
    }

    &--first {
      &::before {
        display: block;
        bottom: 100%;
      }
    }

    &-minus {
      margin-left: 1rem;
      min-width: 0.5rem;
      width: 0.5rem;
      height: 0.2rem;
      background: var(--extra-light-gray);
      border-radius: 0.2rem;
    }
  }
}
