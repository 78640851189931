@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries";

.base-card-list {
  @include flex($flex-wrap: wrap, $align-items: stretch);

  margin: -0.5rem;

  @include laptop {
    margin: -1rem;
  }

  &__item {
    width: calc(100% - 1rem);
    margin: 0.5rem;

    @include tablet {
      width: calc(100% / 3 - 1rem);
    }

    @include laptop {
      width: 30rem;
      margin: 1rem;
    }
  }

  &-slider {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-top: -1rem;
    margin-bottom: -3.5rem;

    &__item {
      max-width: 29rem;
      padding: 1.5rem 0;
      padding-bottom: 3.5rem;
      transform: scale(0.9);
      transition-duration: 300ms;
      transition-timing-function: ease-in-out;

      &.swiper-slide-active {
        transform: scale(1);
      }
    }
  }
}
