.switcher {
  display: flex;
  background-color: var(--extra-light-gray);
  border-radius: 0.8rem;
  width: 100%;
  padding: 0.4rem;

  &_switch {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    width: 100%;
    transition: all 0.3s ease-in;
    padding: 0.3rem 0;
    line-height: normal;
    color: var(--black);
    border-radius: 0.6rem;

    &__active {
      box-shadow: 0 0 2rem 0 rgba(40, 58, 151, 0.2);
      background-color: var(--white);
    }

    &:last-child {
      margin-left: 0.4rem;
    }
  }
}