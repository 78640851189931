@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/utils";

.reset-password-popup {
  @include flex($flex-direction: column, $align-items: center);

  width: 36rem;

  &__icon {
    margin-bottom: 1.6rem;
    min-width: 5rem;
    width: 5rem;
    height: 5rem;
    background-color: var(--extra-light-purple);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.5rem;
    text-align: center;
    color: var(--black);
  }

  &__description {
    margin-bottom: 4rem;
    max-width: 27.2rem;

    &-item {
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: center;
      color: var(--dark-gray);

      display: flex;
      align-items: center;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &-mail {
        font-weight: 600;
        color: var(--dark-blue);
      }
    }
  }
}
