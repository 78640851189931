@import "src/app/styles/mixins/media-queries";

.telegram-block {
  padding: 3rem;

  @include tablet-max {
    padding: 1.5rem;
  }

  &__title {
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 3rem;
    line-height: 4.1rem;
    color: var(--black);
  }

  &__description {
    margin-bottom: 3.2rem;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: var(--black);
  }

  &-controls {
    display: flex;
    &__item {
      padding: 1.25rem;
      background: $lightest-gray;
      text-decoration: none;
      border-radius: $border-radius-small;

      &--telegram-white,
      &--whats-app-white {
        padding: 1.25rem 1.6rem;
      }

      &--telegram-white {
        margin-right: 2rem;
        background: $blue;
      }

      &--whats-app-white {
        background: $green;
      }
    }
  }
}
