@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/utils";

.player-short-stat {
  width: 100%;
  max-width: 43rem;

  &__grid {
    display: grid;
    grid-template-columns: 21rem 1fr;
    grid-template-areas:
      "avatar header"
      "avatar lists"
      "avatar .";

    @include tablet-max {
      grid-template-columns: 12.9rem 1fr;
      grid-template-areas:
        "header header"
        "avatar lists"
        "avatar .";
    }
  }

  &__img {
    grid-area: avatar;
    border-color: $extra-light-gray;
    width: 18rem;
    min-width: 18rem;
    height: 18rem;

    @include tablet-max {
      min-width: 11rem;
      width: 11rem;
      height: 11rem;
    }
  }

  &__header {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    @include tablet-max {
      margin-bottom: 2rem;
    }

    @include mobile {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__status {
    padding: 0.6rem 1.2rem;
    background-color: $lightest-gray;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: (19/14);
    border-radius: 0.4rem;
    display: flex;
    justify-content: space-between;
    position: relative;

    @include mobile {
      display: flex;
      justify-content: center;
    }

    &--verified {
      background-color: $competence-scale-bg;
      color: $green;
    }

    &-date {
      color: var(--gray);
    }
  }

  &__user-name {
    font-weight: bold;
    font-size: 2rem;
    line-height: (27/20);

    @include tablet-max {
      font-size: 1.8rem;
      line-height: (24.51/18);
    }
  }

  &__list {
    @include row($align-items: center, $flex-wrap: wrap, $x-margin: -1.5rem);

    grid-area: lists;
    font-size: 1.6rem;
    line-height: (22/16);
    align-self: start;
    max-width: 46rem;
    position: relative;
    z-index: 1;

    @include tablet-max {
      font-size: 1.5rem;
      margin-top: -0.8rem;
      margin-bottom: -0.8rem;
    }

    &-item {
      @include col($x-margin: 1.5rem);
      @include flex($align-items: center);

      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 1rem;
      cursor: default;

      @include tablet-max {
        margin-top: 0.9rem;
        margin-bottom: 0.9rem;
      }
      &__divider {
        color: var(--extra-light-gray);
        margin: 0 0.5rem;
      }

      &-icon {
        @include flex($align-items: center, $justify-content: center);

        background-color: $extra-light-gray;
        min-width: 2.4rem;
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 1rem;
        font-size: 1.2rem;
        line-height: (16/12);
        border-radius: 50%;
        text-align: center;
        font-weight: 700;
        background-position: center;
        background-repeat: no-repeat;
        user-select: none;

        &--main {
          min-width: 3.5rem;
          width: 3.5rem;
          height: 3.5rem;
          margin: 0;
          font-size: 1.8rem;

          @include tablet-max {
            width: 3.2rem;
            height: 3.2rem;
            min-width: 3.2rem;
            font-size: 1.6rem;
          }
        }
        &--additional {
          min-width: 3.2rem;
          width: 3.2rem;
          height: 3.2rem;
          margin: 0;
          font-size: 1.6rem;
        }

        &--communication {
          color: $white;
          background-color: $blue;
        }

        &--analytics {
          color: $white;
          background-color: $purple;
        }

        &--command {
          color: $white;
          background-color: $sea-wave;
        }

        &--thinking3d {
          color: $white;
          background: $pale-orange;
        }

        &--economics {
          color: $white;
          background: $pink;
        }

        &--jurisprudence {
          color: $white;
          background: $bright-green;
        }
        &--microelectronics {
          color: $white;
          background: #316E99;
        }

        &--general {
          background-image: url("../../images/icons/star-white.svg");
          background-size: 1.2rem auto;
          background-color: $dark-blue;
        }

        &--placeInTable {
          background-image: url("../../images/icons/rate-white.svg");
          background-size: 1.1rem auto;
          background-color: $orange;
        }
      }

      &--lvl-rating {
        width: 3.2rem;
        height: 3.2rem;

        font-weight: 700;
        font-size: $font-size-3;
        display: flex;
        align-items: center;
        text-align: center;

        margin: initial;

        &__main {
          width: 3.5rem;
          height: 3.5rem;
          margin: 0 0.8rem 0 0;
          font-size: $font-size-4;
        }
        &__additional {
          width: 3.2rem;
          height: 3.2rem;
          margin: 0 0.8rem 0 0;
          font-size: $font-size-3;
        }
      }
    }
  }

  &__resume-link {
    margin-top: 2rem;
    justify-content: flex-start;

    @include tablet-max {
      margin-top: 2rem;
    }
  }

  &__approvement {
    margin-bottom: 1rem;
  }
}
