@import "src/app/styles/mixins/media-queries.scss";

.finished-assessments-page {
  &__assessment-results {
    width: 89.5rem;

    @include tablet-max {
      width: 100%;
    }
  }

  &__assessment-parameters {
    width: 63rem;

    @include tablet-max {
      width: 100%;
    }
  }
}
