@import "src/app/styles/mixins/media-queries";

.team-results-grid {
  padding: 0 3rem 3rem;
  flex: 1;
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 0 1rem 3rem;
  }

  &-header {
    display: flex;
    align-items: center;

    &__item {
      padding-top: 1.7rem;
      padding-bottom: 1.7rem;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.6rem;
      text-transform: uppercase;
      color: var(--gray);

      @include mobile {
        font-weight: bold;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &--text-align {
        &_center {
          text-align: center;
        }

        &_left {
          text-align: left;
        }

        &_right {
          text-align: right;
        }
      }
    }
  }

  &__accordion {
    margin-bottom: 2rem;

    &-header {
      flex: 1;
      display: flex;
      align-items: center;

      &-item {
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.5rem;
        color: var(--black);

        &:first-child {
          padding-left: 3rem;

          @include mobile {
            padding-left: 2rem;
          }
        }
      }
    }
  }

  &__table {
    overflow: hidden;
  }
}
