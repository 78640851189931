@import "src/app/styles/variables/variables";

.fines-popover {
  &-input-title {
    font-size: 14px;
    font-weight: 600;
    color: $white;
    margin: 1.2rem 0 ;
  }

  &-content {
    display: flex;
    flex-direction: column;

    &-prompt {
      display: flex;
      flex-direction: column;
      color: var(--white);
      font-size: 1.4rem;
      margin-bottom: 1.6rem;

      span:first-child {
        margin-bottom: 0.8rem;
      }
    }
  }

  &-buttons {
    display: flex;
    margin-top: 2rem;
    justify-content: center;
    flex-direction: row;

    button {
      padding: 0.8rem 2rem;
      font-size: 1.4rem;
      width: 100%;
    }
  }

  &-input {
    &--communication {
      order: 1;
    }
    &--analytics {
      order: 2;
    }
    &--command {
      order: 3;
    }
  }
}
