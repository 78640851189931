@import "src/app/styles/mixins/grid.scss";

.edit-faq-item {
  margin-bottom: 3rem;

  &__header {
    @include flex($align-items: center, $justify-content: space-between);

    margin-bottom: 1rem;

    &-title {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2rem;
      color: $black;
    }
  }
}
