@import "src/app/styles/mixins/grid";
@import "src/app/styles/mixins/media-queries";

.info-block {

  &__container {
    @include flex($justify-content: center);
  }

  &__img {
    margin-right: 2rem;
    min-width: 4rem;
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    border-radius: $border-radius-small;
  }

  &__title {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: $black;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &-text {
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: $black;
      word-break: break-word;
    }
  }
}
