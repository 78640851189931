@import "src/app/styles/mixins/grid";

.user-comments-title {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.comment-input {
  &-wrapper {
    @include flex();
    gap: 1rem;
  }

  &__avatar-image {
    display: block;
    border-radius: 100%;
    width: 5.6rem;
    height: 5.6rem;
  }

  &__input-invisible {
    display: block;
    margin-bottom: 1rem;

    width: 100%;
    border: none;
    outline: none;
    font-size: var(--font-size-2);

    &::placeholder {
      color: var(--gray);
    }
  }

  &__visual-area {
    background: white;
    border: 1px solid var(--extra-light-gray);
    padding: 1.5rem 1rem 0.5rem 1rem;
    width: 100%;
    border-radius: var(--border-radius-small);
    transition: var(--transition);
    max-height: 5.2rem;

    &--focused {
      border-color: var(--dark-blue);
      max-height: 12rem;
    }
  }

  &__button {
    padding: 0.5rem 2rem;
    margin-top: 0.5rem;
    opacity: 0;
    transition: var(--transition);
    transform: translateY(-20%);

    &--visible {
      opacity: 1;
      transform: translateY(0);

      &[disabled] {
        opacity: 0.5;
      }
    }
  }
}
