@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/media-queries.scss";

.base-group {
  @include flex($justify-content: space-between);
  width: 100%;

  &--reverse {
    flex-direction: row-reverse;
  }

  @include tablet-max {
    &--is-mobile-column {
      flex-direction: column;
      align-items: center;
    }
  }

  &--vertical-align-center {
    @include flex($justify-content: space-between, $align-items: center);
  }

  &__item {
    width: calc((100% - 1rem) / 2);

    @include tablet-max {
      &--is-mobile-column {
        @include flex($align-items: center, $justify-content: center);

        width: 100%;

        &:first-child {
          margin-bottom: 2rem;
        }
      }
    }

    &--city-date {
      &:first-child {
        width: 58%;
      }

      &:last-child {
        width: calc(42% - 1rem);
      }
    }

    &--full-width {
      width: 100%;
    }

    &--short-width {
      width: auto;
      margin-left: 1rem;
    }
  }
}
