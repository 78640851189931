@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/grid";
@import "src/app/styles/variables/variables";

.sessions-history-player {
  max-width: 119rem;
  width: 100%;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 2rem;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    background-color: var(--white);
    box-shadow: 0 -4px 16px 0 rgba(40, 58, 151, 0.2);

    &__title {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
    }

    &__buttons {
      display: flex;

      @include tablet-max {
        flex-direction: column;
      }
    }
    &__button {
      background-color: #f8fafb;
      border-radius: 0.8rem;

      &:first-child {
        margin-right: 1.6rem;

        @include tablet-max {
          margin-right: initial;
          margin-bottom: 1.6rem;
        }
      }

      button {
        height: initial;
        padding: 1.2rem 2.4rem;
      }
    }
  }

  &__tabs {
    @include flex(center, center);

    gap: 2rem;
    list-style: none;

    @include mobile {
      overflow-x: scroll;
      gap: 1.6rem;
      justify-content: flex-start;
      padding: 1.5rem;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-wrapper {
      border-radius: $border-radius-large;
      box-shadow: 0 -4px 16px 0 rgba(40, 58, 151, 0.2);
      margin-bottom: 2.4rem;

      @include mobile {
        background: none;
        box-shadow: none;
        margin-bottom: 2rem;
      }
    }

    &__item {
      height: 100%;
      color: $black;
      font-size: 1.6rem;
      white-space: nowrap;
      font-weight: 600;
      cursor: pointer;
      padding: 2rem 0;

      @include mobile {
        box-shadow: 0 0 2rem 0 rgba(40, 58, 151, 0.2);
        border-radius: 20rem;
        background: white;
        transition: $transition;
        height: initial;
        padding: 0.8rem 2rem;
      }

      &--selected {
        pointer-events: none;
        color: $dark-blue;
        box-shadow: inset 0px -0.3rem 0px $dark-blue;

        @include mobile {
          color: white;
          box-shadow: none;
          background: $dark-blue;
        }
      }
    }
  }

  .data-grid {
    &__wrapper {
      box-shadow: 0px -4px 16px 0px rgba(40, 58, 151, 0.2);
      border-radius: $border-radius-large;
    }

    &__table-wrapper {
      height: 62rem;
      min-height: 0;
    }

    &__page-nav-readmore {
      box-shadow: 0px -4px 16px 0px rgba(40, 58, 151, 0.2);
    }

    &__table-wrapper {
      border-radius: $border-radius-large;
    }
  }

  &-without-fines {
    display: flex;
    margin: 5.6rem 0 5.2rem 0;
    justify-content: center;
    font-size: 1.6rem;
    color: $gray;
  }

  .empty-block {
    height: 678px;
  }
}

.sessions-history-data-grid-wrapper--empty {
  max-height: 15rem;
}
