@import "src/app/styles/mixins/media-queries.scss";
@import "src/app/styles/mixins/grid.scss";
@import "src/app/styles/mixins/utils.scss";

.archive-hr-page {
  &__table {
    height: calc(100vh - 20.2rem);

    @include mobile-landscape {
      height: initial;
    }

    @include tablet-landscape {
      height: initial;
    }

    @include laptop {
      height: calc(100vh - 21rem);
    }
  }

  &__nav {
    @include flex($justify-content: center);

    margin: 0 auto 3rem;
    padding: 3.3rem 2.8rem 0;

    @include tablet-max() {
      padding: 2.7rem 2rem 0;
    }
  }
}
